import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, TextField, Tooltip, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import useViewAssetContext from '../../hooks/UseViewAssetContext';
import useCommentsContext from '../../hooks/UseCommentsContext';
import { AddLink } from '@mui/icons-material';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function AssetDeploymentLink({assetCode}) {
  const {setDoneUpdating} = useViewAssetContext();
  const {setDoneUpdate} = useCommentsContext();
  const axios = AxiosCustomConfig();
  const [open,setOpen] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [deploymentRequests, setDeploymentRequests] = useState([]);
  const [deploymentRequestsLoading, setdeploymentRequestsLoading] = useState(false);
  const [opendeploymentRequests, setOpendeploymentRequests] = useState(false);
  const [deploymentRequestsData, setdeploymentRequestsData] = useState({
    asset_code: '',
    deploymentRequests: [],
    distributor_company_id: '', 
    request_code: '',
  });

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);

    setdeploymentRequestsData(
      (prevData) => ({ ...prevData, asset_code: assetCode})
    )

  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!deploymentRequestsData.request_code) {
        setOpenMessageDialog(true);
        setMessage('Please Select deployment request.')
        return
    }
    
    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/AssetsRegistry/addLinkForDeploymentRequest.json',
              data: objectToFormData(deploymentRequestsData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdating((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setdeploymentRequestsData({
      equipment_no: '',
      deploymentRequests: [],
      distributor_company_id: '', 
      request_code: ''
    });
  };

  const getDeploymentRequests = async(ignore) => {
    try {
      setdeploymentRequestsLoading(true)
        const options = {
            method: 'POST',
            url: '/DeploymentRequests/getDeploymentRequestsForLinking.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setDeploymentRequests(response.data);
        }
        setdeploymentRequestsLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!opendeploymentRequests) {
      setDeploymentRequests([]);
    }

    if (!opendeploymentRequests) return undefined
    getDeploymentRequests(ignore)

    return () => {
      ignore = true
    };
  }, [opendeploymentRequests]);


  const hanldeRequestChange = (e, selectValue) => {
    if (selectValue) {
      return setdeploymentRequestsData(
        (prevAccount) => ({ ...prevAccount, deploymentRequests: selectValue, distributor_company_id: selectValue.distributor_company_id, request_code: selectValue.request_code})
      )
    }

    setdeploymentRequestsData(
      (prevAccount) => ({ ...prevAccount, deploymentRequests: [], distributor_company_id: '', request_code: ''})
    )
  }

  return (
    <div>
    <Tooltip title="Link" arrow>
      <IconButton
        onClick={(e) => handleOpen(e)}
        size="small"
        sx={{
          width: 35,
          height: 35,
          borderRadius: 1,
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <AddLink />
      </IconButton>
    </Tooltip>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Link Record</DialogTitle>
      <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={opendeploymentRequests}
                  onOpen={() => {setOpendeploymentRequests(true);}}
                  onClose={() => {setOpendeploymentRequests(false);}}
                  loading={deploymentRequestsLoading}
                  onChange={hanldeRequestChange}
                  options={deploymentRequests}
                  value={deploymentRequestsData.deploymentRequests}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Request*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {deploymentRequestsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AssetDeploymentLink