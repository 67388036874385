
import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Grid, Paper, StyledEngineProvider, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Chip, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, } from '@mui/material';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useAuth from '../../hooks/UseAuth';
import { format } from 'date-fns';
import { TransferAssetRequestProvider } from '../../context/TransferAssetRequestProvider';
import { ArrowDownward, Download } from '@mui/icons-material';
import { Document, Page } from 'react-pdf';


const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);
const WarehouseDetails = () => {
  const { requestCode, id } = useParams();
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [doneUpdateRequest, setDoneUpdateRequest] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [fileType, setFileType] = useState('');
  const [content, setContent] = useState('');

  const [warehouses, setWarehouses] = useState({});
  // const [assetCode, setAssetCode] = useState([]);
  const getAssets = async () => {
    try {
      setLoading(true);
      const options = {
          'method': 'POST',
          'url' : '/Warehouses/getWarehouseDetails.json',
          'data': {'id': id}
      }
      const response = await axios(options);
      const data = response.data;
      setWarehouses(data);
      setLoading(false);
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAssets();
  }, [doneUpdateRequest]);

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  return (
    <TransferAssetRequestProvider>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to={`/warehouse`}
          >
            Warehouse
          </Link>
          <Typography color="text.primary">{warehouses.name}</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container direction='column' spacing={1}>
              <Grid item>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ArrowDownward />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                    <Typography variant="h6">{warehouses.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    </AccordionDetails>
                </Accordion>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={1} style={{ height: '100%' }}>
            <Typography p={2}>Info</Typography>
              <StyledTable sx={{ width: '100%' }} size="small">
                  <TableHead>
                  </TableHead>
                  <TableBody>
                  {Object.entries(warehouses)
                    .filter(([key, _]) =>  key !== 'deleted' && key !== 'company_id')
                    .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                      <TableCell>
                        {key === 'created' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'modified' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : (
                            value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </StyledTable>
          </Paper>
        </Grid>
      </Grid>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </TransferAssetRequestProvider>
  )
}

export default WarehouseDetails