import { Avatar, Box, Chip, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import MessageDialog from '../Utils/MessageDialog'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import { format } from 'date-fns';
import useTransferAssetRequestContext from '../../hooks/UseTransferAssetRequestContext';
import AssetDetailsTooltip from '../DeploymentRequest/AssetDetailsTooltip';
import WarehouseBranchTooltip from '../AssetRegistry/WarehouseBranchTooltip';



function TransferAssetLists({requestCode}) {
  const axios = AxiosCustomConfig();
  const {doneUpdate} = useTransferAssetRequestContext();
  const [assets, setAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'asset_name', headerName: 'Asset No - Asset Model', width: 200,},
    { field: 'status_name', headerName: 'Asset Status', flex: 1, minWidth: 100, wrap: true, },
    { field: 'warehouse_branch', headerName: 'Warehouse Branch',flex: 1, minWidth: 150, wrap: true  },
    { field: 'serial_no', headerName: 'Serial no',flex: 1, minWidth: 150, wrap: true  },
    { field: 'acquisition_date', headerName: 'Acquisition Date',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params);
          return format(date, 'yyyy-MM-dd');
        }
        return ''
      }, wrap: true 
    },
    { field: 'acquisition_value', headerName: 'Acq. Value',flex: 1, minWidth: 100, wrap: true  },
  ];
  const getAssets = async (ignore) => {
    try {
      setLoading(true);
      const options = {
        'method': 'POST',
        'url' : '/TransferAssetRequests/getTransferAssetList.json',
        'data' : {request_code : requestCode}
      }
      const response = await axios(options);
      const data = response.data;
      if(!ignore) {
        setAssets(data)
        setLoading(false);
      }
      
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      if(!ignore) {
        setLoading(false);
      }
      
    }
  }

  useEffect(() => {
    let ignore = false
    getAssets(ignore)
    return ()=>{ignore=true} 
  }, [doneUpdate]);

  return (
    <Box>
    <Box p={1}>
      <Typography>Asset details</Typography>
    </Box>
    <Box sx={{width: '100%', height: 100, display:'table', tableLayout: 'fixed'}} >
    {assets && 
      <DataGrid
        autoHeight
        keepNonExistentRowsSelected
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={assets ? assets : []}
        columns={columns}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        x={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
        />
      }
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </Box>
  )
}


export default TransferAssetLists