
import { CheckCircleOutline} from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';


function InvoiceJobOrder({jobOrderDetails, setDoneUpdateRequest}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);

  const [requests, setRequests] = useState({
    id: '',
    job_order_id: '',
    invoice_number: '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const { job_order_id, id} = jobOrderDetails;
    setRequests((prevData) => ({ 
      ...prevData, 
      job_order_id: job_order_id, 
      id: id,
    }))
  }

  const handleConfirmBtn = async (event) => {
    event.preventDefault();

    try{
      setLoading(true);
      setShowProgress(true);

      const options = {
          'method': 'POST',
          'url' : '/JobOrders/invoiceJobOrder.json',
          'data': objectToFormData(requests)
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setOpen(false)
          setShowProgress(false);
          setDoneUpdateRequest((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }

    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Network error.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }

  };


  const handleClose = () => {
    setOpen(false)
  };

  return (
    <>
      <Button 
        disabled={jobOrderDetails.status === 4 ? false : true} 
        variant='contained' 
        size="small" 
        sx={{
          backgroundColor: '#8d1d75',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#6f185c', // Custom hover background color
            color: '#fff' // Custom hover text color
          }
        }}
        startIcon={<CheckCircleOutline />} 
        onClick={handleOpen}>
        Invoice order
      </Button>

      <Dialog 
        open={open} 
        PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
        fullWidth={true} 
        maxWidth={'sm'}
      >
      <DialogTitle>Invoice order</DialogTitle>
      <DialogContent>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Invoice no.*"
              fullWidth
              variant="standard"
              onChange={(e) => {
                const input = e.target.value;
                // Regular expression to allow up to 11 digits
                if (/^\d{0,11}$/.test(input)) {
                  setRequests((prevData) => ({ ...prevData, invoice_number: input }));
                }
              }}
              value={requests.invoice_number}
            />
            <Typography variant="body2" color="initial">Please provice invoice number to be used.</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth>
          <Button variant="contained" size="small" color='primary' autoFocus onClick={handleConfirmBtn}>Confirm</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error'  onClick={handleClose}>Cancel</Button>
        </FormControl>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default InvoiceJobOrder