import { Box, Chip, Grid, StyledEngineProvider } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddRepairRequest from '../ShopRepair/AddRepairRequest';
import AssetShopRepairLink from './AssetShopRepairLink';
import AssetShopRepairUnlink from './AssetShopRepairUnlink';
import WarehouseBranchTooltip from './WarehouseBranchTooltip';

const AssetShopRepair = ({assetCode}) => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [shopRepairsData, setShopRepairsData] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'id', headerName: 'Id', width: 50, minWidth: 20, wrap: true,
    },
    { field: 'request_code', headerName: 'SR No.',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/shopRepair/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { 
      field: 'status', 
      headerName: 'Request Status',
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      renderCell: (params) => {
        
          const statusName = params.value;
          if (statusName === 0) {
            return <Chip label='For Approval' sx={{ backgroundColor: '#35A7CA' }} />;
          } else if (statusName === 1) {
            return <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4'}} />;
          } else if (statusName === 2) {
            return <Chip label='Sr form for completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />;
          } else if (statusName === 3) {
            return <Chip label='Asset for scrap' sx={{backgroundColor: '#DD1F00', color: '#fff' }} />;
          } else if (statusName === 4) {
            return <Chip label='Rejected' sx={{ backgroundColor: '#000001', color: '#fff' }} />;
          } else if (statusName === 5) {
            return <Chip label='Closed' color='success' />;
          } else if (statusName === 6) {
            return <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />;
          } else if (statusName === 7) {
            return <Chip label='For Billing'  sx={{ backgroundColor: '#2174AE', color: '#fff' }} />;
          } else if (statusName === 8) {
            return <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />;
          } else if (statusName === 9) {
            return <Chip label='Repair on-hold' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />;
          } else if (statusName === 10) {
            return <Chip label='Repaired at shop' sx={{ backgroundColor: '#3AD658' }} />;
          }
          
      }
  },
    { field: 'warehouse_name', headerName: 'Warehouse name',flex: 1, minWidth: 150,
      renderCell: (params) => {
        const warehouse_name = params.value;
        const warehouse_id = params.row.warehouse_id;
        return (
          <WarehouseBranchTooltip id={warehouse_id} value={warehouse_name} />
        );
      }
    },
    { field: 'services', headerName: 'Services',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const services = params.value.split(',').map(service => service.trim());
            return (
                <>
                  <Grid container direction='column'>
                    {services.map((service, index) => (
                      <Grid key={index}>
                        {service}
                      </Grid>
                    ))}
                  </Grid>
                </>
            );
          }
          return null
      },
    },
    { field: 'asset_code', headerName: 'Asset for Evaluation',flex: 1, minWidth: 150,},
    { field: 'requestor_name', headerName: 'Requested by',flex: 1, minWidth: 150,},
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getShopRepairPerAsset.json',
          'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        const data = response.data;

        setShopRepairsData(data)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEditOrDelete]);


  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      
      {/* Container for left-aligned buttons */}
      <Box sx={{ display: 'flex', gap: '10px' }}>
      <AddRepairRequest assetCode={assetCode} setOnEditOrDelete={setOnEditOrDelete}/>
      </Box>

      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Link unlink buttons */}
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AssetShopRepairLink assetCode={assetCode}/>
        <AssetShopRepairUnlink assetCode={assetCode}/>
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        rows={shopRepairsData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        getRowHeight={() => 'auto'}
        autoHeight
        keepNonExistentRowsSelected
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default AssetShopRepair