import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import ReactSignatureCanvas from 'react-signature-canvas';  
import { useTheme } from '@emotion/react';
import useAuth from '../../hooks/UseAuth';
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function AddTransferAsset({assetCode, setOnEdit}) {
  const {auth} = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [destinationWarehouses, setDestinationWarehouses] = useState([]);
  const [openDestinationWarehouse, setOpenDestinationWarehouse] = useState(false);
  const [destinationLoading, setDestinationLoading] = useState(false);
  const [transferRequest, setTransferRequest] = useState({
    assetSelected: [],
    asset_code: '',
    warehouseSelected: [],
    destinationWarehouseSelected: [],
    warehouse_unique_code: '',
    warehouse_name: '',
    destination_warehouse_unique_code: '',
    destination_warehouse_name: '',
    hauling_rates: [],
    hauling_rate_name : '',
    hauling_rate_unique_code : '',
    hauling_rate : '',
    store_contact_no : '',
    remarks : '',
  });
  const handleOpenSignature= () => {
    setOpenSignature(true)
  }
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };
  const theme = useTheme();
  const [haulingRates, setHaulingRates] = useState([]);
  const [openHaulingRate, setOpenHaulingRate] = useState(false);
  const [haulingLoading, setHaulingLoading] = useState(false);
  const signatureRef = useRef();
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  
  const handleSaveSignature = () => {
    const dataURL = signatureRef.current.toDataURL('image/png');
    if (dataURL) {

        setTransferRequest((prevData) => ({ ...prevData, signature_url: dataURL}))
        setOpenSignature(false);
    }
    
  };
  const [openSignature, setOpenSignature] = useState(false);
  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      let asset_code = selectValue.map(item => item.asset_code);
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, assetSelected: selectValue, asset_code: asset_code})
      )
    } 
    setTransferRequest((prevAccount) => ({ ...prevAccount, assetSelected: [], asset_code: ''}))
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, warehouseSelected: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    } 

    setTransferRequest((prevAccount) => ({ ...prevAccount, warehouseSelected: [], warehouse: []}))
  }
  const hanldeDestinationWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, destinationWarehouseSelected: selectValue, destination_warehouse_unique_code: selectValue.warehouse_unique_code, destination_warehouse_name: selectValue.warehouse_name})
      )
    } 

    setTransferRequest((prevAccount) => ({ ...prevAccount, destinationWarehouseSelected: [], warehouse: []}))
  }

  const hanldeHaulingRequestsChange = (e, selectValue) => {
    if (selectValue) {
      setTransferRequest((prevAccount) => ({ ...prevAccount, hauling_rates: selectValue, hauling_rate_name: selectValue.label, hauling_rate_unique_code: selectValue.unique_code, hauling_rate: selectValue.rate}))
    }
  }

  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getDestinationWarehouses = async(ignore) => {
    try {
      setDestinationLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getDestinationWarehouses.json',
            data: {warehouse_unique_code: transferRequest.warehouse_unique_code}
        }
        const response = await axios(options);
        if (!ignore) {
          setDestinationWarehouses(response.data);
        }
        setDestinationLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  
  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAssetsForTransferRequest.json',
            data: {warehouse_unique_code: transferRequest.warehouse_unique_code, asset_code: assetCode}
        }
        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getHaulingRates = async (active) => {
    try {
      setHaulingLoading(true)
      const options = {
          method: 'POST',
          url: '/HaulingRates/getHaulingRatesForAutocomplete.json',
      }
      const response = await axios(options);
      if (active) {
        setHaulingRates(response.data);
      }
      setHaulingLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openAssets) {
      setAssets([]);
    }

    if (!openAssets) return undefined
    getAssets(ignore)

    return () => {
      ignore = true
    };
  }, [openAssets]);

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  useEffect(() => {
    let ignore = false;
    if (!openDestinationWarehouse) {
      setDestinationWarehouses([]);
    }

    if (!openDestinationWarehouse) return undefined
    getDestinationWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openDestinationWarehouse]);


  useEffect(() => {
    let active = true;

    if (!openHaulingRate) {
      return undefined;
    }

    getHaulingRates(active);

    return () => {
      active = false;
    };

  }, [openHaulingRate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return
    
    if (transferRequest.warehouseSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Warehouse is required.');
      return false;
    }
    if (transferRequest.assetSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Asset is required.');
      return false;
    }
    if (transferRequest.destinationWarehouseSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Destination Warehouse is required.');
      return false;
    }

    try {

      const options = {
          method: 'POST',
            url: '/TransferAssetRequests/addRequest.json',
            data: objectToFormData(transferRequest),
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setOnEdit((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setTransferRequest({
      assetSelected: [],
      asset_code: '',
      warehouseSelected: [],
      destinationWarehouseSelected: [],
      warehouse_unique_code: '',
      warehouse_name: '',
      destination_warehouse_unique_code: '',
      destination_warehouse_name: '',
      hauling_rates: [],
      hauling_rate_name : '',
      hauling_rate_unique_code : '',
      hauling_rate : '', 
      store_contact_no : '', 
      remarks : '', 
    });
  };

  const validateRequiredFields = () => {
    const requiredFields = ['warehouseSelected', 'assetSelected', 'destinationWarehouseSelected', 'hauling_rates', 'store_contact_no' , 'store_contact_name', 'remarks'];

    for (const field of requiredFields) {
      if (!transferRequest[field] || (Array.isArray(transferRequest[field]) && transferRequest[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }

    }

    return true;
  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>Create Request</Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >
      <DialogTitle>Add New transfer request</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openWarehouse}
                onOpen={() => {setOpenWarehouse(true);}}
                onClose={() => {setOpenWarehouse(false);}}
                loading={warehouseLoading}
                onChange={hanldeWarehouseChange}
                options={warehouses}
                value={warehouses.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Centigen Branch*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="combo-box-demo"
                open={openAssets}
                onOpen={() => {setOpenAssets(true);}}
                onClose={() => {setOpenAssets(false);}}
                loading={loading}
                onChange={hanldeAssetsChange}
                options={assets}
                value={assets.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select asset*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openDestinationWarehouse}
                onOpen={() => {setOpenDestinationWarehouse(true);}}
                onClose={() => {setOpenDestinationWarehouse(false);}}
                loading={destinationLoading}
                onChange={hanldeDestinationWarehouseChange}
                options={destinationWarehouses}
                value={destinationWarehouses.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Destination Warehouse*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {destinationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openHaulingRate}
                      onOpen={() => {setOpenHaulingRate(true);}}
                      onClose={() => {setOpenHaulingRate(false);}}
                      onChange={hanldeHaulingRequestsChange}
                      options={haulingRates}
                      value={haulingRates.label}
                      loading={haulingLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hauling Rate*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {haulingLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField

                      name="name"
                      label="Store Contact Person*"
                      fullWidth
                      variant="standard"
                      onChange={(e) => setTransferRequest((prevAccount) => ({ ...prevAccount, store_contact_name: e.target.value }))}

                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>

                    <TextField

                        name="name"
                        label="Store contact no.*"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                          const input = e.target.value;
                          // Regular expression to allow up to 11 digits
                          if (/^\d{0,11}$/.test(input)) {
                            setTransferRequest((prevData) => ({ ...prevData, store_contact_no: input }));
                          }
                        }}
                        value={transferRequest.store_contact_no}
                        
                    />

                </FormControl>
            </Grid>

            {(Number(auth.role) === 0) && <Grid item xs={12}>
              <Typography>Remarks*:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Input Remarks*" 
                  onChange={(e) => setTransferRequest((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                />
            </Grid>}

            <Grid item xs={12}>
              
                  <Button 
                    variant='contained' 
                    color='primary' 
                    style={{ marginBottom: '8px' }} 
                    onClick={handleOpenSignature}>
                    Signature
                  </Button>
                  <Dialog open={openSignature} onClose={() => setOpenSignature(false)}>
                    <DialogTitle>Add signature</DialogTitle>
                    <DialogContent>
                    <ReactSignatureCanvas 
                      penColor='black'
                      canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
                      ref={signatureRef}
                    />
                    </DialogContent>
                    <DialogActions>
                      <Button 
                          onClick={handleSaveSignature} 
                          variant='contained'
                          sx={{
                                color: theme.palette.primary.main,
                                bgcolor: '#7acda1',
                                '&:hover': {
                                  bgcolor: '#ACE1C3',
                                },
                              }}  
                          >Done</Button>
                      <Button onClick={handleClearSignature} color="primary">clear</Button>
                      <Button onClick={() => setOpenSignature(false)} color="error">cancel</Button>
                    </DialogActions>
                  </Dialog>

              </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default AddTransferAsset