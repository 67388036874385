import { useTheme } from '@emotion/react';
import { CheckCircleOutline, Close, CloudUpload } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, StyledEngineProvider, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useShopRepairContext from '../../hooks/useShopRepairContext';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function ForScrapRequest({repairDetails, setonRejectOrApprove}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useShopRepairContext();
  const [open, setOpen] = useState(false);
  const signatureRef = useRef();
  const [requests, setRequests] = useState({
    id: '',
    request_code: '',
    asset_code: '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const { request_code, id, asset_code} = repairDetails;
    setRequests((prevData) => ({ 
      ...prevData, 
      request_code: request_code, 
      id: id,
      asset_code: asset_code,
    }))
  }

  const handleForSrap = async (event) => {
    event.preventDefault();

    try{
      setLoading(true);
      setShowProgress(true);

      const formData = new FormData();
      formData.append('request_code', requests.request_code);
      formData.append('id', requests.id);
      formData.append('asset_code', requests.asset_code);

      const options = {
          'method': 'POST',
          'url' : '/ShopRepairs/forScrapRequest.json',
          'data': formData
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setDoneUpdate((prev) => !prev)
          setOpen(false)
          setShowProgress(false);
          setonRejectOrApprove((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Network error.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }

  };


  const handleClose = () => {
    setOpen(false)
  };

  return (
    <>
      <Button 
        disabled={repairDetails.status === 0 || repairDetails.status === 5 || repairDetails.status === 4 || repairDetails.status === 3  || repairDetails.status === 10 || repairDetails.status === 7 || repairDetails.status === 8 ? true : false} 
        variant='contained' 
        size="small" 
        color='primary' 
        style={{ marginRight: '8px' }} 
        startIcon={<CheckCircleOutline />} 
        onClick={handleOpen}>
        For scrap
      </Button>

      <Dialog 
        open={open} 
        PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
        fullWidth={true} 
        maxWidth={'sm'}
        >
      <DialogContent>
          <DialogContentText id="alert-dialog-description" align='center'>
            Are you sure you want to scrap this Request? <p> <strong> {repairDetails.request_code}</strong>  </p>
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="contained" size="small" color='error' autoFocus onClick={handleForSrap}>Save</Button>
        </FormControl>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default ForScrapRequest