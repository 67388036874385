import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';

function AddAccessoryType({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [accessory, setAccessory] = useState({
    accessory_type: '',
  });

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!accessory.accessory_type) {
        setOpenMessageDialog(true);
        setMessage('Accessory type is required')
        return
    }

    if (!isValidSpecialCharacter(accessory.accessory_type)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Accessory type.')
        return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AccessoryTypes/addAccessoryType.json',
              data: objectToFormData(accessory),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }


  const resetForm = () => {
    setAccessory({
      accessory_type : ''
    });
  };


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Add</Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Accessory type</DialogTitle>
      <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  name="accessory_type"
                  label="Accessory type*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAccessory((prevAccount) => ({ ...prevAccount, accessory_type: e.target.value }))}
                  value={accessory.accessory_type}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddAccessoryType