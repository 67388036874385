import { Box, Chip, Grid, StyledEngineProvider } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddServiceRequest from '../ServiceRequest/AddServiceRequest';
import AssetServiceCallLink from './AssetServiceCallLink';
import AsssetServiceCallUnlink from './AsssetServiceCallUnlink';
import WarehouseBranchTooltip from './WarehouseBranchTooltip';

const AssetServiceRequest = ({assetCode}) => {
  const axios = AxiosCustomConfig();
  const [onEdit, setOnEdit] = useState(false);
  const [assetDisposalData, setAssetDisposalData] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'id', headerName: 'Id', width: 50, minWidth: 20, wrap: true,
    },
    { field: 'request_code', headerName: 'Request no',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/serviceCallRequests/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { 
      field: 'status', 
      headerName: 'Service Status',
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      renderCell: (params) => {
        
          const statusName = params.value;
          if (statusName === 0) {
            return <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4'}} />;
          } else if (statusName === 1) {
            return <Chip label='Repaired at site' sx={{ backgroundColor: '#3AD658' }} />;
          } else if (statusName === 2) {
            return <Chip label='Unit for pullout' sx={{ backgroundColor: '#F1FA67' }} />;
          } else if (statusName === 3) {
            return <Chip label='Cancelled' sx={{ backgroundColor: '#CA863E', color: '#fff' }} />;
          } else if (statusName === 4) {
            return <Chip label='For Billing' sx={{ backgroundColor: '#2174AE', color: '#fff' }} />;
          } else if (statusName === 5) {
            return <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />;
          } else {
            return '';
          }
          
      }
  },
    { field: 'warehouse_name', headerName: 'Warehouse Branch',flex: 1, minWidth: 150,
      renderCell: (params) => {
        const warehouse_name = params.value;
        const warehouse_id = params.row.warehouse_id;
        return (
          <WarehouseBranchTooltip id={warehouse_id} value={warehouse_name} />
        );
      }
    },
    { field: 'asset_name', headerName: 'Assets',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const services = params.value.split(',').map(service => service.trim());
            return (
                <>
                  <Grid container direction='column'>
                    {services.map((service, index) => (
                      <Grid key={index}>
                        {service}
                      </Grid>
                    ))}
                  </Grid>
                </>
            );
          }
          return null
      },
    },
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150,},
    { field: 'created', headerName: 'Date Uploaded',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd');
      }, wrap: true 
    },
    { field: 'completion_date', headerName: 'Completeion Date',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        if(params) {
          const date = new Date(params);
          return format(date, 'yyyy-MM-dd');
        }
        return '';
      }, wrap: true 
    },
    { field: 'complaints', headerName: 'Complaints',flex: 1, minWidth: 150,},
    { field: 'repair_cost', headerName: 'Repair Cost',flex: 1, minWidth: 150,},
    { field: 'services', headerName: 'Service Call',flex: 1, minWidth: 300,
      renderCell: (params) => {
        if(params.value) {
          const services = params.value.split(',').map(service => service.trim());
          return (
              <>
                <Grid container direction='column'>
                  {services.map((service, index) => (
                    <Grid key={index}>
                      {service}
                    </Grid>
                  ))}
                </Grid>
              </>
          );
        }
        return null
      },
    },
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getServiceRequestsPerAsset.json',
          'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        const data = response.data;

        setAssetDisposalData(data)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEdit]);


  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      
      {/* Container for left-aligned buttons */}
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AddServiceRequest assetCode={assetCode} setOnEdit={setOnEdit}/>
      </Box>

      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Link unlink buttons */}
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AssetServiceCallLink assetCode={assetCode}/>
        <AsssetServiceCallUnlink assetCode={assetCode}/>
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        rows={assetDisposalData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        getRowHeight={() => 'auto'}
        autoHeight
        keepNonExistentRowsSelected
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default AssetServiceRequest