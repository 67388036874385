import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Link } from 'react-router-dom';

const AssetModelTooltip = ({ id, value }) => {
  const axios = AxiosCustomConfig();
  const [assetModelDetails, setAssetModelDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAssetModelDetails = async () => {
    if (!assetModelDetails && !loading) {
      
      setLoading(true);
      try {
        const options = {
          'method': 'POST',
          'url' : '/AssetModels/getAssetModelDetails.json',
          'data': {id:id}
        }

        const response = await axios(options);
        
        const { data } = response;
        setAssetModelDetails(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching asset model details:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  

  const tooltipContent = assetModelDetails ? (
    <>
      <div>ID: {assetModelDetails.id}</div>
      <div>Model: {assetModelDetails.model}</div>
      <div>Type Size: {assetModelDetails.asset_type_size}</div>
      <div>Brand: {assetModelDetails.asset_brand}</div>
    </>
  ) : (
    'Loading...'
  );

  return (
    <Tooltip
      title={tooltipContent}
      arrow
      placement="right"
      onOpen={fetchAssetModelDetails}
      disableHoverListener={loading}
    >
      <Link to={`/asset/models/${id}`} style={{ cursor: 'pointer' }}>
        {value}
      </Link>
    </Tooltip>
  );
};

export default AssetModelTooltip;
