import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Link } from 'react-router-dom';

const AssetDetailsTooltip = ({ id, value }) => {
  const axios = AxiosCustomConfig();
  const [assetDetails, setAssetDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchassetDetails = async () => {
    if (!assetDetails && !loading) {
      try {
        setLoading(true);
        // Replace with your API call to fetch customer details by ID
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getAssetDetails.json',
          'data': {'id': id}
        }
        const response = await axios(options);
        const {data} = response;
        
        setAssetDetails(data); // Save customer details in state
        setLoading(false);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  useEffect(() => {
    setAssetDetails(null);
  }, [id]);
  
  const tooltipContent = assetDetails ? (
    <>
      <div>Id: {assetDetails.id}</div>
      <div>Equipment no: {assetDetails.equipment_no}</div>
      <div>Asset name: {assetDetails.name}</div>
    </>
  ) : (
    'Loading...'
  );

  return (
    <Tooltip
      title={tooltipContent}
      arrow
      placement="right"
      onOpen={fetchassetDetails}
      disableHoverListener={loading}
      key={id}
    >
      <Link to={`/asset/${id}`}>
        {value}
      </Link>
    </Tooltip>
  );
};

export default AssetDetailsTooltip;
