import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';


function EditAccessories({accessoryData, setDoneUpdateRequest, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    accessory: '',
    accessories: [],
    quantity: '',
    request_code: '',
    id: ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [accessories, setAccessories] = useState([]);
  const [openAccessories, setOpenAccessories] = useState(false);
  const [loadingAccessories, setLoadingAccessories] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { accessories, id, quantity, request_code} = accessoryData;
    setEditData((prevData) => ({ 
      ...prevData, 
      accessory: accessories || prevData.accessories, 
      accessories: {'label': accessories, accessory: accessories}, 
      quantity: quantity || prevData.quantity, 
      id: id || prevData.id,
      request_code: request_code || prevData.request_code,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      accessory: '',
      accessories: [],
      quantity: '',
      request_code: '',
      id: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!editData.accessory) {
      setOpenMessageDialog(true);
      setMessage('Accessory is required')
      return
    }

    if (!editData.quantity) {
      setOpenMessageDialog(true);
      setMessage('Quantity is required')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
            url: '/DeploymentRequestAccessories/updateAccessory.json',
            data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setDoneUpdateRequest((prev) => !prev)
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }


  const handleAccessoriesChange = (e, selectValue) => {
    if (selectValue) {

      setEditData((prevData) => ({ ...prevData, accessories: selectValue, accessory: selectValue.accessory}))
    }
  }

  const getAccessories = async (ignore) => {
    try {
      setLoadingAccessories(true)
        const options = {
          method: 'POST',
          url: '/Accessories/getAccessoriesForAutoComplete.json',
      }

      const response = await axios(options);
      if (!ignore) {
        setAccessories(response.data);
      }
      
      setLoadingAccessories(false)
    } catch (error) {
      console.log('Error info: ', error);
    } finally {
      setLoadingAccessories(false)
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAccessories) {
      setAccessories([])
    }

    if (!openAccessories) return undefined
    getAccessories(ignore)

    return () => {
      ignore = true;
    }
  }, [openAccessories]);

  return (
    <>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Accessory type</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>

            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                open={openAccessories}
                onOpen={() => {setOpenAccessories(true);}}
                onClose={() => {setOpenAccessories(false);}}
                onChange={handleAccessoriesChange}
                loading={loadingAccessories}
                options={accessories}
                value={editData.accessories}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Accessories*"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAccessories ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  name="quantity"
                  label="Quantity"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    const input = e.target.value;
                    // Regular expression to allow up to 11 digits
                    if (/^\d{0,64}$/.test(input)) {
                      setEditData((prevData) => ({ ...prevData, quantity: input }));
                    }
                  }}
                  value={editData.quantity}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </>
  )
}

export default EditAccessories