import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Grid, Paper, StyledEngineProvider, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Chip, Breadcrumbs } from '@mui/material';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useAuth from '../../hooks/UseAuth';
import { format } from 'date-fns';
import ApproveStoreTransfer from './ApproveStoreTransfer';
import { StoreToStoreProvider } from '../../context/StoreToStoreContext';
import OriginAssetStore from './OriginAssetStore';
import DestinationAssetStore from './DestinationAssetStore';
import EditTransferRequest from './EditTransferRequest';
import DeployTransferRequest from './DeployTransferRequest';
import MoreOptionBtn from './MoreOptionBtn';
import BlankTapor from '../Utils/BlankTapor';
import BlankTalaf from '../Utils/BlankTalaf';
import TadrPrint from '../Utils/TadrPrint';

const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);


const StoreToStoreTransfersDetails = () => {
  const { requestCode } = useParams();
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [doneUpdateRequest, setDoneUpdateRequest] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [requestDetails, setRequestDetails] = useState({});
  const [assetCode, setAssetCode] = useState([]);
  const getAssets = async () => {
    try {
      setLoading(true);
      const options = {
          'method': 'POST',
          'url' : '/StoreToStoreTransfers/getTransferRequestsDetails.json',
          'data': {'request_code': requestCode}
      }
      const response = await axios(options);
      const data = response.data;
      setRequestDetails(data);
      console.log(data);
      setAssetCode(data.asset_code)
      setLoading(false);
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAssets();
  }, [doneUpdateRequest]);


  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  return (
    <StoreToStoreProvider>
    <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/storeToStoreTransfers"
          >
            Store-to-store transfers
          </Link>
          <Typography color="text.primary">Details</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Grid container direction='column' spacing={1}>
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%', padding: '8px', display: 'flex', gap:'8px', flexWrap: 'wrap' }}>
                    {(Number(auth.role) === 4 || Number(auth.role) === 0)  && (
                      <>
                        <EditTransferRequest requestDetails={requestDetails} setDoneUpdateRequest={setDoneUpdateRequest}/>
                      </>
                    )}
                    {(Number(auth.role) === 6 || Number(auth.role) === 0 ) && (
                      <>
                        <DeployTransferRequest requestDetails={requestDetails} setDoneUpdateRequest={setDoneUpdateRequest}/>
                      </>
                    )}
                    {Number(auth.role) === 3 || Number(auth.role) === 2 || Number(auth.role) === 5 || Number(auth.role) === 0  ? (
                      <>
                        <ApproveStoreTransfer requestDetails={requestDetails} setDoneUpdateRequest={setDoneUpdateRequest}/>
                      </>
                    ) : ''}

                    {Number(auth.role) === 6 || Number(auth.role) === 2 || Number(auth.role) === 0 || Number(auth.role) === 3 ? (
                      <>
  
                        <TadrPrint requestDetails={requestDetails}/>
                        <BlankTalaf requestDetails={requestDetails} />
                        <BlankTapor requestDetails={requestDetails} />
                        <MoreOptionBtn requestDetails={requestDetails} setDoneUpdateRequest={setDoneUpdateRequest}/>

                      </>
                    ) : ''}
                    
                  </Paper>
              </Grid>
              
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%' }}>
                  {assetCode.length > 0 &&
                    <OriginAssetStore asset_code={assetCode} requestCode={requestCode}/>
                  }
                  </Paper>
              </Grid>
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%' }}>
                  {assetCode.length > 0 &&
                    <DestinationAssetStore asset_code={assetCode} requestCode={requestCode}/>
                  }
                  </Paper>
              </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={1} style={{ height: '100%' }}>
            <Typography p={2}>Repair details</Typography>
              <StyledTable sx={{ width: '100%' }} size="small">
                  <TableHead>
                  </TableHead>
                  <TableBody>
                  {Object.entries(requestDetails)

                    .filter(([key, _]) => key !== 'origin_account_code' && key !== 'origin_branch_name' && key !== 'destination_account_code' && key !== 'destination_branch_name' && key !== 'destination_account_name' && key !== 'destination_branch_code' && key !== 'origin_account_name' && key !== 'origin_branch_code' && key !== 'model' && key !== 'blank_tadr_display' && key !== 'blank_talaf_display' && key !== 'blank_tapor_display' && key !== 'signature_image_convert' && key !== 'destination_account_unique_code' && key !== 'origin_account_unique_code' && key !== 'signature_requestor_image_convert' && key !== 'warehouse_unique_code' && key !== 'distributor_company_id') 
                    .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                      <TableCell style={key === 'status' ? { backgroundColor: value === '0' ? 'blue' : value === '1' ? 'orange' : 'inherit' } : {}}>
                        {key === 'status' ? (
                            value === 0 ? <Chip label='For approval' sx={{ backgroundColor: '#35A7CA' }}  /> : 
                            value === 1 ? <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4' }} />: 
                            value === 2 ? <Chip label='Deployed' sx={{ backgroundColor: '#3AD658' }} />: 
                            value === 3 ? <Chip label='Unproductive' sx={{ backgroundColor: '#E8484F', color: '#fff' }} />: 
                            value === 4 ? <Chip label='Unproductive & cancelled' sx={{ backgroundColor: '#843B10', color: '#fff' }} />: 
                            value === 5 ? <Chip label='Hold' sx={{ backgroundColor: '#F8FD6A' }} />: 
                            value === 6 ? <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />: 
                            value === 7 ? <Chip label='Rejected' sx={{ backgroundColor: '#000001', color: '#fff' }} />: 
                            ''
                        ) : key === 'date_added' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'completion_date' ? (
                            value !== null ? format(value, 'yyyy-MM-dd') : ''
                        ) : key === 'pullout_date' ? (
                            value !== null ? format(value, 'yyyy-MM-dd') : ''
                        ) : key === 'approver_signature' ? (
                            value !== '' ? <img
                              src={value}
                              alt='after repair'
                              style={{ width: 200, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                            /> : ''
                        ) : key === 'signature_of_requestor' ? (
                            value ? <img
                              src={value}
                              alt='after repair'
                              style={{ width: 200, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                            /> : ''
                        ) : (
                            value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </StyledTable>
          </Paper>
        </Grid>
      </Grid>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </StoreToStoreProvider>
  )
}

export default StoreToStoreTransfersDetails