import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { ListItemIcon, useTheme } from '@mui/material';
import { Check, ExpandMore, ToggleOn } from '@mui/icons-material';
import AddWarehouseCentigen from './AddWarehouseCentigen';
import AssetToWarehouseUpdateStatus from './AssetToWarehouseUpdateStatus';
import ChangeAssetSpecs from './ChangeAssetSpecs';
import SetAuditDate from './SetAuditDate';
import UpdateAssetStatus from './UpdateAssetStatus';
import AddAssetToCustomer from './AddAssetToCustomer';

export default function MoreOptionBtn({assetsData, setOnEditOrDelete}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openAddWh, setOpenAddWh] = useState(false);
  const [openAssetStatus, setOpenAssetStatus] = useState(false);
  const [status, setStatus] = useState('');
  const [openChangeSpecs, setOpenChangeSpecs] = useState(false);
  const [openSetAuditDate, setOpenSetAuditDate] = useState(false);
  const [openUpdateAssetStatus, setOpenUpdateAssetStatus] = useState(false);
  const [openAssetToCustomer, setOpenAssetToCustomer] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenWh = () => {
    setOpenAddWh(true);
    setAnchorEl(null);
  }

  const handleOpenChangeAssetSpecs = () => {
    setOpenChangeSpecs(true);
    setAnchorEl(null);
  }
  const handleOpenSetAuditDate = () => {
    setOpenSetAuditDate(true);
    setAnchorEl(null);
  }
  const handleOpenUpdateAssetStatus = () => {
    setOpenUpdateAssetStatus(true);
    setAnchorEl(null);
  }

  const handleOpenAssetDeployable = () => {
    setStatus('Deployable');
    setOpenAssetStatus(true);
    setAnchorEl(null);
  }

  const handleOpenAssetForRepair = () => {
    setStatus('For Repair');
    setOpenAssetStatus(true);
    setAnchorEl(null);
  }
  
  const handleOpenAssetForScrap = () => {
    setStatus('For Scrap');
    setOpenAssetStatus(true);
    setAnchorEl(null);
  }
  const handleOpenAssetToCustomer = () => {
    setOpenAssetToCustomer(true);
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark}} 
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        More actions <ExpandMore />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOpenWh}>
          <ListItemIcon>
            <Check fontSize="small" />
          </ListItemIcon>
          Add WH
        </MenuItem>
        <MenuItem sx={{ color: '#159076' }} onClick={handleOpenAssetDeployable}>
          <ListItemIcon>
            <Check fontSize="small" sx={{ color: '#159076' }}/>
          </ListItemIcon>Asset to Centigen - Deployable
        </MenuItem>
        <MenuItem sx={{ color: '#93700e' }} onClick={handleOpenAssetForRepair}>
          <ListItemIcon>
            <Check fontSize="small" sx={{ color: '#93700e' }}/>
          </ListItemIcon>Asset to Centigen - For Repair
        </MenuItem>
        <MenuItem sx={{ color: '#c97070' }} onClick={handleOpenAssetForScrap}>
          <ListItemIcon>
            <Check fontSize="small" sx={{ color: '#c97070' }}/>
          </ListItemIcon>Asset to Centigen - For Scrap
        </MenuItem>
        <MenuItem onClick={handleOpenChangeAssetSpecs}>
          <ListItemIcon>
            <ToggleOn fontSize="small"/>
          </ListItemIcon>Change asset specs
        </MenuItem>
        <MenuItem onClick={handleOpenAssetToCustomer}>
          <ListItemIcon>
            <Check fontSize="small"/>
          </ListItemIcon>Set Asset to Customer
        </MenuItem>
        <MenuItem onClick={handleOpenSetAuditDate}>
          <ListItemIcon>
            <Check fontSize="small"/>
          </ListItemIcon>Set Audit date
        </MenuItem>
        <MenuItem  sx={{ color: '#f6bf26' }} onClick={handleOpenUpdateAssetStatus}>
          <ListItemIcon>
            <Check fontSize="small" sx={{ color: '#f6bf26' }}/>
          </ListItemIcon>Update asset status
        </MenuItem>
      </Menu>

      {openAddWh && <AddWarehouseCentigen open={openAddWh} setOpen={setOpenAddWh} assetsData={assetsData} />}
      {openAssetStatus && <AssetToWarehouseUpdateStatus status={status} open={openAssetStatus} setOpen={setOpenAssetStatus} assetsData={assetsData} />}
      {openChangeSpecs && <ChangeAssetSpecs open={openChangeSpecs} setOpen={setOpenChangeSpecs} assetsData={assetsData} />}
      {openSetAuditDate && <SetAuditDate open={openSetAuditDate} setOpen={setOpenSetAuditDate} assetsData={assetsData} />}
      {openUpdateAssetStatus && <UpdateAssetStatus open={openUpdateAssetStatus} setOpen={setOpenUpdateAssetStatus} assetsData={assetsData} />}
      {openAssetToCustomer && <AddAssetToCustomer open={openAssetToCustomer} setOpen={setOpenAssetToCustomer} assetsData={assetsData} />}
    </>
  );
}