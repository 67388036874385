import { useTheme } from '@emotion/react';
import { CheckCircleOutline } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import useTransferAssetRequestContext from '../../hooks/UseTransferAssetRequestContext';
import useAuth from '../../hooks/UseAuth';
import dayjs from 'dayjs';


function ConfirmDeployAssets({transferRequests, setDoneUpdateRequest}) {
  const {auth} = useAuth();
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useTransferAssetRequestContext();
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState({
    id: '',
    request_code: '',
    completion_date: '',
    status: ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const { request_code, id, origin_warehouse_unique_code, destination_warehouse_unique_code, assets, status} = transferRequests;
    setRequests((prevData) => ({ 
      ...prevData, 
      request_code: request_code, 
      id: id,
      origin_warehouse_unique_code: origin_warehouse_unique_code,
      destination_warehouse_unique_code: destination_warehouse_unique_code,
      asset_codes: assets,
      status: status
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (requests.status === 1) {
      setOpenMessageDialog(true);
      setMessage('Error! Cannot confirm deploy the asset not yet delivered.');
      return false;
    }

    if (!requests.completion_date) {
      setOpenMessageDialog(true);
      setMessage('Completion date is required.');
      return false;
    }

    try{
      setLoading(true);
      setShowProgress(true);

      const options = {
          'method': 'POST',
          'url' : '/TransferAssetRequests/confirmDeployAssetRequest.json',
          'data': objectToFormData(requests)
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setDoneUpdate((prev) => !prev)
          setDoneUpdateRequest((prev) => !prev)
          setOpen(false)
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }

  };

  const handleClose = () => {
    setOpen(false)
  };

  const disableButton = (transferRequests) => {
    if(transferRequests.status !== 3 || transferRequests.origin_username === auth.username) {
      return true
    }

    return false;
  }

  return (
    <>
      <Button 
        disabled={disableButton(transferRequests)} 
        variant='contained' 
        size="small" 
        color='success' 
        startIcon={<CheckCircleOutline />} 
        onClick={handleOpen}>
        Confirm Deploy
      </Button>

      <Dialog 
        open={open} 
        PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
        fullWidth={true} 
        maxWidth={'md'}
        >
      <DialogTitle>Close request</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="initial">Date completion:</Typography>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Completion Date*"
                            value={requests.completion_date}
                            onChange={(newValue) => setRequests({...requests, completion_date: newValue})}
                            maxDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth>
          <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default ConfirmDeployAssets