import { useTheme } from '@emotion/react';
import { CheckCircleOutline } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, styled, StyledEngineProvider, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import useTransferAssetRequestContext from '../../hooks/UseTransferAssetRequestContext';
import useAuth from '../../hooks/UseAuth';
import dayjs from 'dayjs';


function DeployTransferAssetRequest({transferRequests, setDoneUpdateRequest}) {
  const {auth} = useAuth();
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useTransferAssetRequestContext();
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState({
    id: '',
    request_code: '',
    date_served: '',
    tadr_copy: '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const { request_code, id,destination_warehouse_unique_code} = transferRequests;
    setRequests((prevData) => ({ 
      ...prevData, 
      request_code: request_code || prevData.request_code, 
      id: id || prevData.id,
      destination_warehouse_unique_code: destination_warehouse_unique_code,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!requests.date_served) {
      setOpenMessageDialog(true);
      setMessage('Date served is required.');
      return false;
    }
    if (!requests.tadr_copy) {
      setOpenMessageDialog(true);
      setMessage('Please select TADR copy.');
      return false;
    }

    try{
      setLoading(true);
      setShowProgress(true);

      const options = {
          'method': 'POST',
          'url' : '/TransferAssetRequests/deployAssetRequest.json',
          'data': objectToFormData(requests)
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setDoneUpdate((prev) => !prev)
          setDoneUpdateRequest((prev) => !prev)
          setOpen(false)
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }

  };

  const handleClose = () => {
    setOpen(false)
  };

  const disableButton = (transferRequests) => {
    if(transferRequests.status !== 1 || transferRequests.destination_username === auth.username) {
      return true
    }

    return false;
  }

  const handleTADRChange = (event) => {
    const file = event.target.files[0];
    setRequests((prev) => ({...prev, tadr_copy: file}));
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <>
      <Button 
        disabled={disableButton(transferRequests)} 
        variant='contained' 
        size="small" 
        color='primary' 
        startIcon={<CheckCircleOutline />} 
        onClick={handleOpen}>
        Deploy
      </Button>

      <Dialog 
        open={open} 
        PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
        fullWidth={true} 
        maxWidth={'md'}
        >
      <DialogTitle>Deploy asset</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="initial">Date served:</Typography>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Deploy date*"
                            value={requests.date_served}
                            onChange={(newValue) => setRequests({...requests, date_served: newValue})}
                            maxDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
            <Typography variant="subtitle2" color="initial">TADR Copy:   {requests.tadr_copy && requests.tadr_copy.name}</Typography>
            <FormControl fullWidth>
              <Button
                component="label"
                variant="contained" 
                size="small" 
                color='primary'
              >Upload TADR Copy
              <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleTADRChange}/>
              </Button> 
            </FormControl>
          </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth>
          <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default DeployTransferAssetRequest