import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Link } from 'react-router-dom';

const CustomerDetailsTooltip = ({ unique_code, value }) => {
  const axios = AxiosCustomConfig();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCustomerDetails = async () => {
    if (!customerDetails && !loading) {
      try {
        setLoading(true);
        // Replace with your API call to fetch customer details by ID
        const options = {
          'method': 'POST',
          'url' : '/ApiAccounts/getStoreDetails.json',
          'data': {unique_code:unique_code}
        }
        const response = await axios(options);
        const {data} = response.data;
        setCustomerDetails(data); // Save customer details in state
        setLoading(false);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      } finally {
        setLoading(false);
      }
    }
  };


  const tooltipContent = customerDetails ? (
    <>
      <div>Id: {customerDetails.id}</div>
      <div>Ship to / Business Address: {customerDetails.business_address}</div>
      <div>Customer No: {customerDetails.customer_no}</div>
      <div>Distributor/Chain Account: {customerDetails.distributor_account || customerDetails.customer_chain_static}</div>
    </>
  ) : (
    'Loading...'
  );

  return (
    <Tooltip
      title={tooltipContent}
      arrow
      placement="right"
      onOpen={fetchCustomerDetails}
      disableHoverListener={loading}
    >
      <Link to={`/customers/${unique_code}`}>
        {value}
      </Link>
    </Tooltip>
  );
};

export default CustomerDetailsTooltip;
