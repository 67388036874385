import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';

function AddAccessories({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [accessory, setAccessory] = useState({
    accessory: '',
    asset_types: [],
    asset_type_name: '',
    accessory_types: [],
    accessory_type: '',
    quantity: '',
  });
  const [assetTypes, setAssetTypes] = useState([]);
  const [openAssetTypes, setOpenAssetTypes] = useState(false);
  const [loadingAssetTypes, setLoadingAssetTypes] = useState(false);
  const [accessoryType, setAccessoryType] = useState([]);
  const [openAccessoryType, setOpenAccessoryType] = useState(false);
  const [loadingAccessoryType, setLoadingAccessoryType] = useState(false);


  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!accessory.accessory) {
        setOpenMessageDialog(true);
        setMessage('Accessory is required')
        return
    }
    if (accessory.asset_types.length === 0) {
        setOpenMessageDialog(true);
        setMessage('Asset type is required')
        return
    }

    if (accessory.accessory_types.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Accessory type is required')
      return
    }

    if (!isValidSpecialCharacter(accessory.accessory)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Brand Name.')
        return
    }

    if (!isValidSpecialCharacter(accessory.quantity) && accessory.quantity !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in quantity.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/Accessories/addAccessory.json',
              data: objectToFormData(accessory),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }


  const resetForm = () => {
    setAccessory({
      accessory: '',
      asset_types: [],
      asset_type_name: '',
      accessory_types: [],
      accessory_type: '',
      quantity: '',
    });
  };

  const handleAssetTpyesChange = (e, selectValue) => {
    if (selectValue) {
      setAccessory((prevData) => ({ ...prevData, asset_types: selectValue, asset_type_name: selectValue.asset_type_name}))
    }
  }
  const handleAccessoryTypeChange = (e, selectValue) => {
    if (selectValue) {
      console.log(selectValue.accessory_type);
      
      setAccessory((prevData) => ({ ...prevData, accessory_types: selectValue, accessory_type: selectValue.accessory_type}))
    }
  }


  const getAssetTypes = async (ignore) => {
    try {
      setLoadingAssetTypes(true)
        const options = {
          method: 'POST',
          url: '/AssetTypes/getAssetTypeNameAndCode.json',
      }

      const response = await axios(options);
      if (!ignore) {
        setAssetTypes(response.data);
      }
      
      setLoadingAssetTypes(false)
    } catch (error) {
      console.log('Error info: ', error);
    } finally {
      setLoadingAssetTypes(false)
    }
  }

  const getAccessoryTypes = async (ignore) => {
    try {
      setLoadingAccessoryType(true)
        const options = {
          method: 'POST',
          url: '/AccessoryTypes/getAccessoryTypesForAutoComplete.json',
      }

      const response = await axios(options);
      if (!ignore) {
        setAccessoryType(response.data);
      }
      
      setLoadingAccessoryType(false)
    } catch (error) {
      console.log('Error info: ', error);
    } finally {
      setLoadingAccessoryType(false)
    }
  }


  useEffect(()=>{
    let ignore = false;

    if (!openAssetTypes) {
      setAssetTypes([])
    }

    if (!openAssetTypes) return undefined
    getAssetTypes(ignore)

    return () => {
      ignore = true;
    }
  }, [openAssetTypes]);

  useEffect(()=>{
    let ignore = false;

    if (!openAccessoryType) {
      setAccessoryType([])
    }

    if (!openAccessoryType) return undefined
    getAccessoryTypes(ignore)

    return () => {
      ignore = true;
    }
  }, [openAccessoryType]);


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Add</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        sx: { height: '400px' },
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true}
      maxWidth={'md'}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'center',
        },
      }}
    >
      <DialogTitle>Add New Accessory</DialogTitle>
      <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  name="accessory"
                  label="Accessory*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAccessory((prevAccount) => ({ ...prevAccount, accessory: e.target.value }))}
                  value={accessory.accessory}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                open={openAccessoryType}
                onOpen={() => {setOpenAccessoryType(true);}}
                onClose={() => {setOpenAccessoryType(false);}}
                onChange={handleAccessoryTypeChange}
                loading={loadingAccessoryType}
                options={accessoryType}
                value={accessoryType.label}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Accessory type*"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAccessoryType ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                open={openAssetTypes}
                onOpen={() => {setOpenAssetTypes(true);}}
                onClose={() => {setOpenAssetTypes(false);}}
                onChange={handleAssetTpyesChange}
                loading={loadingAssetTypes}
                options={assetTypes}
                value={assetTypes.asset_type_code}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Asset type*"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAssetTypes ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  name="quantity"
                  label="Quantity"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    const input = e.target.value;
                    // Regular expression to allow up to 11 digits
                    if (/^\d{0,64}$/.test(input)) {
                      setAccessory((prevData) => ({ ...prevData, quantity: input }));
                    }
                  }}
                  value={accessory.quantity}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddAccessories