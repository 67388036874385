import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Link } from 'react-router-dom';

const WarehouseBranchTooltip = ({ id, value }) => {
  const axios = AxiosCustomConfig();
  const [warehouseDetails, setwarehouseDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchwarehouseDetails = async () => {
    if (!warehouseDetails && !loading) {
      
      setLoading(true);
      try {
        const options = {
          'method': 'POST',
          'url' : '/Warehouses/getWarehouseDetails.json',
          'data': {id:id}
        }

        const response = await axios(options);
        
        const { data } = response;
        setwarehouseDetails(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching asset model details:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  

  const tooltipContent = warehouseDetails ? (
    <>
      <div>ID: {warehouseDetails.id}</div>
      <div>Code: {warehouseDetails.code}</div>
      <div>Name: {warehouseDetails.name}</div>
    </>
  ) : (
    'Loading...'
  );

  return (
    <Tooltip
      title={tooltipContent}
      arrow
      placement="right"
      onOpen={fetchwarehouseDetails}
      disableHoverListener={loading}
    >
      <Link to={`/warehouse/${id}`} style={{ cursor: 'pointer' }}>
        {value}
      </Link>
    </Tooltip>
  );
};

export default WarehouseBranchTooltip;
