import { Avatar, Box, Chip, CircularProgress, StyledEngineProvider, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import AddRequest from '../DeploymentRequest/AddRequest';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AssetDeploymentLink from './AssetDeploymentLink';
import AssetDeploymentRequestUnlink from './AssetDeploymentRequestUnlink';


function AssetDeploymentRequest({assetCode}) {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [deploymentRequests, setDeploymentRequests] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);

  const columns = [
    { field: 'request_code', headerName: 'Request code', flex: 1, minWidth: 100, wrap: true,
        renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/request/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }
    },
    { field: 'signature_image', headerName: 'Signature', width: 150,
      renderCell: (params) => (
        <Avatar alt="Avatar" src={params.value} />
      )
    },
    { 
        field: 'status_name', 
        headerName: 'Request Status',
        flex: 1, 
        minWidth: 100, 
        wrap: true,
        renderCell: (params) => {
          
            const statusName = params.value;
            if (statusName === 'For approval') {
              return <Chip label={statusName} sx={{ backgroundColor: '#35A7CA' }} />;
            } else if (statusName === 'Processing') {
              return <Chip label={statusName} sx={{ backgroundColor: '#D7D6D4' }} />;
            } else if (statusName === 'Deployed') {
              return <Chip label={statusName} sx={{ backgroundColor: '#3AD658' }} />;
            } else if (statusName === 'Rejected') {
              return <Chip label={statusName} sx={{ backgroundColor: '#000001', color: '#fff' }} />;
            } else if (statusName === 'Cancelled') {
              return <Chip label={statusName} sx={{ backgroundColor: '#D2893C' }} />;
            } else if (statusName === 'Unproductive') {
              return <Chip label={statusName} sx={{ backgroundColor: '#E8484F', color: '#fff'  }} />;
            } else if (statusName === 'Unproductive & cancelled') {
              return <Chip label={statusName} sx={{ backgroundColor: '#DD1F00', color: '#fff' }} />;
            } else if (statusName === 'Hold') {
              return <Chip label={statusName} sx={{ backgroundColor: '#F8FD6A' }} />;
            } else {
              return <Chip label={statusName} color='primary' 
                
              />;
            }
            
        }
    },
    { 
      field: 'account_name', 
      headerName: 'Store name',
      flex: 1, 
      minWidth: 150, 
      wrap: true,
      renderCell: (params) => {
        const store_name = params.value;
        const unique_code = params.row.account_unique_code;
        const fetchCustomerDetails = async () => {
          try {
            // Replace with your API call to fetch customer details by ID
            const options = {
              'method': 'POST',
              'url' : '/ApiAccounts/getStoreDetails.json',
              'data': {unique_code:unique_code}
            }
            const response = await axios(options);
            const {data} = response.data;
            setCustomerDetails(data); // Save customer details in state
          } catch (error) {
            console.error("Error fetching customer details:", error);
          }
        };
        return (
          <Tooltip
            title={customerDetails ? (
              <>
                <div>Id: {customerDetails.id}</div>
                <div>Ship to / Business Address: {customerDetails.business_address}</div>
                <div>Customer No: {customerDetails.customer_no}</div>
                <div>Distributor/Chain Account: {customerDetails.distributor_account || customerDetails.customer_chain_static}</div>
              </>
            ) : 'Loading...'}
            arrow
            placement="right"
            onOpen={fetchCustomerDetails}
          >
            <Link to={`/customers/${unique_code}`}>
              {store_name}
            </Link>
          </Tooltip>
        );
      }
    },
    { field: 'deployment_type_name', headerName: 'Deployment type',flex: 1, minWidth: 150},
    { field: 'asset_name', headerName: 'Asset',flex: 1, minWidth: 150},
    { field: 'requestor_name', headerName: 'Requested By',flex: 1, minWidth: 150},
  ];

  useEffect(() => {
    let ignore = false;
    const getAssetTypes = async (ignore) => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getDeploymentRequestsPerAsset.json',
          'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        const data = response.data;
        if (!ignore) {
          setDeploymentRequests(data)
        }
        
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      } finally {
        setLoading(false);
      }

      return () => {
        ignore = true;
        setLoading(false);
      }
    }

    getAssetTypes(ignore);
  }, [onEditOrDelete]);
  return (
    <div>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* Container for left-aligned buttons */}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <AddRequest setOnEditOrDelete={setOnEditOrDelete}/>
        </Box>

        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Link unlink buttons */}
        <Box sx={{ display: 'flex', gap: '10px'  }}>
          <AssetDeploymentLink assetCode={assetCode}/>
          <AssetDeploymentRequestUnlink assetCode={assetCode}/>
        </Box>
      </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows= {deploymentRequests}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        getRowHeight={() => 'auto'}
        autoHeight
        keepNonExistentRowsSelected
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </div>
  )
}

export default AssetDeploymentRequest