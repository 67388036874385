import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';

function AddAccessories({requestCode, setDoneUpdateRequest, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [accessory, setAccessory] = useState({
    accessory: '',
    accessories: [],
    quantity: '',
    request_code: '',
  });
  const [accessories, setAccessories] = useState([]);
  const [openAccessories, setOpenAccessories] = useState(false);
  const [loadingAccessories, setLoadingAccessories] = useState(false);


  const handleOpen = () => {
    setOpen(true)
    setAccessory((prevData) => ({
      ...prevData,
      request_code : requestCode
    }))
  }

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    
    if (!accessory.accessory) {
        setOpenMessageDialog(true);
        setMessage('Accessory is required')
        return
    }

    if (!accessory.quantity) {
        setOpenMessageDialog(true);
        setMessage('Quantity is required')
        return
    }

    if (!isValidSpecialCharacter(accessory.quantity) && accessory.quantity !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in quantity.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/DeploymentRequestAccessories/addAccessory.json',
              data: objectToFormData(accessory),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setDoneUpdateRequest((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }


  const resetForm = () => {
    setAccessory({
      accessory: '',
      accessories: [],
      quantity: '',
      request_code: '',
    });
  };

  const handleAccessoriesChange = (e, selectValue) => {
    if (selectValue) {

      setAccessory((prevData) => ({ ...prevData, accessories: selectValue, accessory: selectValue.accessory}))
    }
  }

  const getAccessories = async (ignore) => {
    try {
      setLoadingAccessories(true)
        const options = {
          method: 'POST',
          url: '/Accessories/getAccessoriesForAutoComplete.json',
      }

      const response = await axios(options);
      if (!ignore) {
        setAccessories(response.data);
      }
      
      setLoadingAccessories(false)
    } catch (error) {
      console.log('Error info: ', error);
    } finally {
      setLoadingAccessories(false)
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAccessories) {
      setAccessories([])
    }

    if (!openAccessories) return undefined
    getAccessories(ignore)

    return () => {
      ignore = true;
    }
  }, [openAccessories]);


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Add</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        sx: { height: '400px' },
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true}
      maxWidth={'md'}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'center',
        },
      }}
    >
      <DialogTitle>Add New Accessory</DialogTitle>
      <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                open={openAccessories}
                onOpen={() => {setOpenAccessories(true);}}
                onClose={() => {setOpenAccessories(false);}}
                onChange={handleAccessoriesChange}
                loading={loadingAccessories}
                options={accessories}
                value={accessories.label}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Accessories*"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAccessories ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  name="quantity"
                  label="Quantity*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    const input = e.target.value;
                    // Regular expression to allow up to 11 digits
                    if (/^\d{0,64}$/.test(input)) {
                      setAccessory((prevData) => ({ ...prevData, quantity: input }));
                    }
                  }}
                  value={accessory.quantity}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddAccessories