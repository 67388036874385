import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import useViewAssetContext from '../../hooks/UseViewAssetContext';
import useCommentsContext from '../../hooks/UseCommentsContext';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function AddAssetToCustomer({open, setOpen, assetsData}) {
  const {setDoneUpdating} = useViewAssetContext();
  const {setDoneUpdate} = useCommentsContext();
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [openStoreData, setOpenStoreData] = useState(false);
  const [loadingStoreData, setLoadingStoreData] = useState(false);
  const [customerData, setCustomerData] = useState({
    account_unique_code: '', 
    store_name: '',
    jde_syspro_customer_no: '',
    distributor_company_id: '',
    store_names: [],

  });

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!customerData.jde_syspro_customer_no) {
        setOpenMessageDialog(true);
        setMessage('Store name is required')
        return
    }
    
    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/AssetsRegistry/addAssetToCustomer.json',
              data: objectToFormData(customerData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdating((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setCustomerData({
      account_unique_code: '', 
      store_name: '',
      jde_syspro_customer_no: '',
      distributor_company_id: '',
      store_names: [],
    });
  };

  const getStoreData = async (ignore) => {
    try {
        setLoadingStoreData(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getAllStoresForRequest.json',
        }

        const response = await axios(options);
        if(!ignore){
          setStoreData(response.data);
          setLoadingStoreData(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openStoreData) return undefined
    getStoreData(ignore);

    return () => {
      ignore = true;
    }
  }, [openStoreData]);

  useEffect(() => {
    if (open && assetsData) {
      const { equipment_no, jde_syspro_customer_no, store_name, account_unique_code } = assetsData;
      setCustomerData({
        asset_code: equipment_no,
        old_account_unique_code: account_unique_code,
      });
    }

  }, [open, assetsData]);

  const handleStoreAccontsChange = (e, selectValue) => {
    if (selectValue) {
      setCustomerData((prevData) => ({ ...prevData, store_name: selectValue.account_name, jde_syspro_customer_no: selectValue.account_code, account_unique_code: selectValue.account_unique_code, distributor_company_id: selectValue.distributor_company_id}))
    }
  }

  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        sx: { height: '400px' },
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true}
      maxWidth={'md'}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'center',
        },
      }}
    >
      <DialogTitle>Set Asset to Customer</DialogTitle>
      <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openStoreData}
                  onOpen={() => {setOpenStoreData(true);}}
                  onClose={() => {setOpenStoreData(false);}}
                  loading={loadingStoreData}
                  onChange={handleStoreAccontsChange}
                  options={storeData}
                  value={storeData.label}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store/Business name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingStoreData ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit} autoFocus>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose}>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddAssetToCustomer