import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Chip, CircularProgress, Grid, Paper, StyledEngineProvider, Table, TableBody, TableCell,TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import ProcessDialog from '../Utils/ProcessDialog';
import useAuth from '../../hooks/UseAuth';
import EditAssets from './EditAssets';
import AssetDeploymentRequest from './AssetDeploymentRequest';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AssetPulloutRequest from './AssetPulloutRequest';
import AssetShopRepair from './AssetShopRepair';
import AssetServiceRequest from './AssetServiceRequest';
import AssetStoreToStoreTransfer from './AssetStoreToStoreTransfer';
import AssetDisposalRequest from './AssetDisposalRequest';
import TransferAssetRequest from './TransferAssetRequest';
import AssetAudit from './AssetAudit';
import WarehouseAuditRequest from './WarehouseAuditRequest';
import AssetComments from './AssetComments';
import MoreOptionBtn from './MoreOptionBtn';
import useViewAssetContext from '../../hooks/UseViewAssetContext';
import { ViewAssetProvider } from '../../context/ViewAssetProvider';
import AssetModelTooltip from './AssetModelTooltip';
import WarehouseBranchTooltip from './WarehouseBranchTooltip';

const components = {
  AssetDeploymentRequest,
  AssetPulloutRequest,
  AssetShopRepair,
  AssetServiceRequest,
  AssetStoreToStoreTransfer,
  AssetDisposalRequest,
  TransferAssetRequest,
  AssetAudit,
  WarehouseAuditRequest,
};

const StyledTable = styled(Table)(
    ({ theme }) => `
    td,th
    {
        border: none;
    }
    `,
  );

function ViewAsset({assetsData}) {
  const {doneUpdate} = useViewAssetContext();
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const {id} = useParams();
  const [assetDetails, setAssetDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    let ignore = false;

    const getRequestDetails  = async (ignore) => {
        try {
            setLoading(true);
            const options = {
                'method': 'POST',
                'url' : '/AssetsRegistry/getAssetDetails.json',
                'data': {'id': id}
              }
              const response = await axios(options);
              const data = response.data;

              if (!ignore) {
                setAssetDetails(data);
              }
        } catch (error) {
            setOpenMessageDialog(true);
            setMessage(` ${error.toJSON().message}. Network error.`);
        } finally {
            setLoading(false);
        }
    }

    getRequestDetails(ignore)
    return () => {
        ignore = true
    };
  }, [id, onEditOrDelete, doneUpdate]);

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const accordionData = [
    { id: 1, title: "Deployment Request ", component: "AssetDeploymentRequest" },
    { id: 2, title: "Pullout Request", component: "AssetPulloutRequest" },
    { id: 3, title: "Shop Repair", component: "AssetShopRepair" },
    { id: 4, title: "Service Call Request", component: "AssetServiceRequest" },
    { id: 5, title: "Store-to-store transfers", component: "AssetStoreToStoreTransfer" },
    { id: 6, title: "Asset Disposal", component: "AssetDisposalRequest" },
    { id: 7, title: "Transfer Asset", component: "TransferAssetRequest" },
    { id: 8, title: "Asset Audit", component: "AssetAudit" },
    { id: 9, title: "Warehouse Audit", component: "WarehouseAuditRequest" },
    // Add more data as needed
  ];

  const initialExpandedState = accordionData.map((_, index) => index < 2); // First two true, rest false
  const [expanded, setExpanded] = useState(initialExpandedState);
  const [hasFetched, setHasFetched] = useState(initialExpandedState);

  const handleChange = (index) => () => {
    const newExpanded = expanded.map((item, i) => (i === index ? !item : item));
    setExpanded(newExpanded);

    if (!hasFetched[index]) {
      fetchData(index);
    }
  };

  const fetchData = (index) => {
    const newHasFetched = hasFetched.map((item, i) => (i === index ? true : item));
    setHasFetched(newHasFetched);
  };

  return (
    <>
    <Box mb={3}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          to="/asset/registry"
        >
          Asset
        </Link>
        <Typography color="text.primary">Details</Typography>
      </Breadcrumbs>
    </Box>

    <Grid container spacing={1}>
      <Grid item xs={12} md={6} lg={8}>
        <Grid container direction='column' spacing={4}>
          {Number(auth.role) === 3 || Number(auth.role) === 2 || Number(auth.role) === 0 ? (
            <Grid item>
              <Paper elevation={1} style={{ height: '100%', padding: '8px', display: 'flex', gap: 5 }}>
                <EditAssets assetsData={assetDetails} setOnEditOrDelete={setOnEditOrDelete}/>
                <MoreOptionBtn assetsData={assetDetails}/>
              </Paper>
            </Grid>
          ) : ''}

          {accordionData.map((item, index) => {
            const Component = components[item.component];
            return (
              <Grid item key={item.id}>
                <Accordion expanded={expanded[index]} onChange={handleChange(index)}>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls={`panel${item.id}-content`}
                    id={`panel${item.id}-header`}
                  >
                    <Typography variant="h6">{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!loading && Component && hasFetched[index] ? <Component assetCode={assetDetails.equipment_no} /> : null}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}

          <Grid item>
            <Paper elevation={1} style={{ height: '100%', padding: '8px' }}>
              {!loading && <AssetComments assetCode={assetDetails.equipment_no}/>}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      

      <Grid item xs={12} md={6} lg={4}>
        <Paper elevation={1} style={{ height: '100%' }}>
          <Typography p={2}>Asset details</Typography>
            <StyledTable sx={{ width: '100%' }} size="small">
                <TableHead>
                </TableHead>
                <TableBody>
                {Object.entries(assetDetails)
                  .filter(([key, _]) => key !== 'warehouse_unique_code' && key !== 'asset_type_code' && key !== 'image' && key !== 'warehouse_id' && key !== 'asset_model_id' && key !== 'account_unique_code')
                  .map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                      {key === 'jde_system_status' ? (
                        value === 0 ? <Chip label='Active' color='success' /> : 
                        value === 1 ? <Chip label='Deleted' color='error' />: 
                        value === 2 ? <Chip label='Inactive' color='warning' />: 
                        value === 3 ? <Chip label='For write-off' color='primary' />: 
                        ''
                      ) : key === 'asset_model' ? (
                        <AssetModelTooltip id={assetDetails.asset_model_id} value={value} />
                      ) : key === 'warehouse_branch' ? (
                        <WarehouseBranchTooltip id={assetDetails.warehouse_id} value={value} />
                      ) : (
                        value
                      )}
                  </TableRow>
                ))}
                </TableBody>
            </StyledTable>

        </Paper>
      </Grid>
     
    </Grid>


    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </>
  )
}

export default ViewAsset