import { useTheme } from '@emotion/react';
import { Build, Close, CloudUpload } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid,IconButton,StyledEngineProvider, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useShopRepairContext from '../../hooks/useShopRepairContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function SetAssetToRepair({repairDetails, setonRejectOrApprove}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useShopRepairContext();
  const [open, setOpen] = useState(false);
  const signatureRef = useRef();
  const [requests, setRequests] = useState({
    id: '',
    request_code: '',
    date_repaired: '',
    remarks: '',
    image_after_repair: [],
    image_temp: [],
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const { request_code, id, remarks, asset_code} = repairDetails;
    setRequests((prevData) => ({ 
      ...prevData, 
      request_code: request_code, 
      asset_code: asset_code, 
      id: id,
      remarks: remarks,
    }))
  }

  const handleForSrap = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return


    try{
      setLoading(true);
      setShowProgress(true);

      const formData = new FormData();
      if (requests.image_after_repair.length >  0) {
        requests.image_after_repair.forEach((file, index) => {
          formData.append(`image_after_repair_${index}`, file);
        });
      }

      if (requests.image_temp.length > 0) {
        requests.image_temp.forEach((file, index) => {
          formData.append(`image_temp_${index}`, file);
        });
      }

      formData.append('request_code', requests.request_code);
      formData.append('id', requests.id);
      formData.append('remarks', requests.remarks);
      formData.append('date_repaired', requests.date_repaired);
      formData.append('asset_code', requests.asset_code);

      const options = {
          'method': 'POST',
          'url' : '/ShopRepairs/setAssetToRepair.json',
          'data': formData
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setDoneUpdate((prev) => !prev)
          setOpen(false)
          setShowProgress(false);
          setonRejectOrApprove((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Network error.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }

  };

  const validateRequiredFields = () => {
    const requiredFields = ['date_repaired', 'remarks', 'date_repaired', 'image_after_repair', 'image_temp'];

    for (const field of requiredFields) {
      if (!requests[field] || (Array.isArray(requests[field]) && requests[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }
    }

    return true;
  };

  const handleClose = () => {
    setOpen(false)
  };

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = requests.image_after_repair.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setRequests(prevState => ({
      ...prevState,
      image_after_repair: updatedImages
    }));
  };
  const removeImageTemp = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = requests.image_temp.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setRequests(prevState => ({
      ...prevState,
      image_temp: updatedImages
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setRequests((prevAccount) => ({ ...prevAccount, image_after_repair: [...prevAccount.image_after_repair, ...files]}))
  };
  const handleImageTempFileChange = (event) => {
    const files = Array.from(event.target.files);
    setRequests((prevAccount) => ({ ...prevAccount, image_temp: [...prevAccount.image_temp, ...files]}))
  };

  return (
    <>
      <Button 
        disabled={repairDetails.status === 0 || repairDetails.status === 5 || repairDetails.status === 4 || repairDetails.status === 3  || repairDetails.status === 10 || repairDetails.status === 7 || repairDetails.status === 8 ? true : false} 
        variant='contained' 
        size="small" 
        color='primary' 
        style={{ marginRight: '8px' }} 
        startIcon={<Build />} 
        onClick={handleOpen}>
        Set asset to repaired
      </Button>

      <Dialog 
        open={open} 
        PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
        fullWidth={true} 
        maxWidth={'sm'}
        >
      <DialogTitle>Set asset to repaired (SR)</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
         Are you sure you want to set Assets to Repaired?
        </DialogContentText>

        <Box m={5}>
        {/* for spacing */}
        </Box>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                    label="Date Repaired*"
                    value={requests.date_repaired}
                    onChange={(newValue) => setRequests({...requests, date_repaired: dayjs(newValue).format('YYYY-MM-DD')})}
                    maxDate={dayjs()}
                />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
            <Typography>Remarks*:</Typography>
              <Textarea 
                aria-label="minimum height" 
                minRows={3} 
                placeholder="Input Remarks*" 
                onChange={(e) => setRequests((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                value={requests.remarks}
              />
        </Grid>

        <Grid item xs={12} mb={2}>
          <Typography mt={1}>Photo after repair*:</Typography>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUpload />}
            >
            Upload images
            <VisuallyHiddenInput
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
          {requests.image_after_repair.length > 0 && (
            <>
              <Typography mt={2}>Selected Image:</Typography>
              <ul>
                {requests.image_after_repair.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                      <Close />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Grid>
        <Grid item xs={12} mb={2}>
          <Typography mt={1}>Testing lowest temperature*:</Typography>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUpload />}
            >
            Upload images
            <VisuallyHiddenInput
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageTempFileChange}
            />
          </Button>
          {requests.image_temp.length > 0 && (
            <>
              <Typography mt={2}>Selected Image temperature:</Typography>
              <ul>
                {requests.image_temp.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <IconButton color='error' aria-label="remove" onClick={() => removeImageTemp(index)}>
                      <Close />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth>
          <Button variant="contained" size="small" color='primary' autoFocus onClick={handleForSrap}>Confirm</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose}>Cancel</Button>
        </FormControl>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default SetAssetToRepair