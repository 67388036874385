import { Box, Chip, Grid, StyledEngineProvider, Tooltip, Typography } from '@mui/material'
import { DataGrid, gridClasses, GridFooterContainer } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddServiceRequest from './AddServiceRequest';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomPagination from '../Utils/CustomPagination';
import useSortModel from '../../hooks/UseSortModel';

const CustomFooter = ({ totalRepairCost, selectedRows}) => {
  const formattedTotal = new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(totalRepairCost);
  
  return (
    <GridFooterContainer>
      {/* Your custom footer content */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', padding: '8px', backgroundColor: '#f5f5f5' }}>
        {selectedRows > 0 ? (
          <Typography variant="subtitle1">
            {selectedRows} {selectedRows === 1 ? 'row' : 'rows'} selected
          </Typography>
        ) : (
          <Typography variant="subtitle1">
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Total Repair Cost: {formattedTotal}
        </Typography>
      </Box>
      
      {/* Default pagination footer */}
      <CustomPagination /> 
      {/* <GridPagination/> */}
    </GridFooterContainer>
  );
};


const ServiceRequest = () => {
  const axios = AxiosCustomConfig();
  const [onEdit, setOnEdit] = useState(false);
  const [assetDisposalData, setAssetDisposalData] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'service_request';
  const modelTable = 'ServiceRequests';
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [totalRepairCost, setTotalRepairCost] = useState(0);
  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);
  const [customerDetails, setCustomerDetails] = useState([]);
  const header_columns = [
    { field: 'id', headerName: 'Id', width: 50, minWidth: 20, wrap: true,
    },
    { field: 'request_code', headerName: 'Request no',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/serviceCallRequests/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { 
      field: 'status', 
      headerName: 'Service Status',
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      renderCell: (params) => {
          const statusName = params.value;
          if (statusName === 0) {
            return <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4'}} />;
          } else if (statusName === 1) {
            return <Chip label='Repaired at site' sx={{ backgroundColor: '#3AD658' }} />;
          } else if (statusName === 2) {
            return <Chip label='Unit for pullout' sx={{ backgroundColor: '#F1FA67' }} />;
          } else if (statusName === 3) {
            return <Chip label='Cancelled' sx={{ backgroundColor: '#CA863E', color: '#fff' }} />;
          } else if (statusName === 4) {
            return <Chip label='For Billing' sx={{ backgroundColor: '#2174AE', color: '#fff' }} />;
          } else if (statusName === 5) {
            return <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />;
          } else {
            return '';
          }
          
      }
  },
    { field: 'warehouse_name', headerName: 'Warehouse Branch',flex: 1, minWidth: 150,},
    { field: 'account_code', headerName: 'JDE/ Syspro no',flex: 1, minWidth: 150,},
    { field: 'account_name', headerName: 'Customer',flex: 1, minWidth: 150,
      renderCell: (params) => {
        const store_name = params.value;
        const unique_code = params.row.account_unique_code;
        const fetchCustomerDetails = async () => {
          try {
            // Replace with your API call to fetch customer details by ID
            const options = {
              'method': 'POST',
              'url' : '/ApiAccounts/getStoreDetails.json',
              'data': {unique_code:unique_code}
            }
            const response = await axios(options);
            const {data} = response.data;
            setCustomerDetails(data); // Save customer details in state
          } catch (error) {
            console.error("Error fetching customer details:", error);
          }
        };
        return (
          <Tooltip 
            title={customerDetails ? (
              <>
                <div>Id: {customerDetails.id}</div>
                <div>Ship to / Business Address: {customerDetails.business_address}</div>
                <div>Customer No: {customerDetails.customer_no}</div>
                <div>Distributor/Chain Account: {customerDetails.distributor_account || customerDetails.customer_chain_static}</div>
              </>
            ) : 'Loading...'}
            arrow
            placement="right"
            onOpen={fetchCustomerDetails}
          >
            <Link to={`/customers/${unique_code}`}>
              {store_name}
            </Link>
          </Tooltip>
        );
      }
    },
    { field: 'asset_name', headerName: 'Assets',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const services = params.value.split(',').map(service => service.trim());
            return (
                <>
                  <Grid container direction='column'>
                    {services.map((service, index) => (
                      <Grid key={index}>
                        {service}
                      </Grid>
                    ))}
                  </Grid>
                </>
            );
          }
          return null
      },
    },
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150,},
    { field: 'created', headerName: 'Date Uploaded',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd');
      }, wrap: true 
    },
    { field: 'completion_date', headerName: 'Completeion Date',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        if(params) {
          const date = new Date(params);
          return format(date, 'yyyy-MM-dd');
        }
        return '';
      }, wrap: true 
    },
    { field: 'complaints', headerName: 'Complaints',flex: 1, minWidth: 150,},
    { field: 'repair_cost', headerName: 'Repair Cost',flex: 1, minWidth: 150,},
    { field: 'services', headerName: 'Service Call',flex: 1, minWidth: 300,
      renderCell: (params) => {
        if(params.value) {
          const services = params.value.split(',').map(service => service.trim());
          return (
              <>
                <Grid container direction='column'>
                  {services.map((service, index) => (
                    <Grid key={index}>
                      {service}
                    </Grid>
                  ))}
                </Grid>
              </>
          );
        }
        return null
      },
    },
    { 
      field: 'invoice_number', 
      headerName: 'Invoice no.',
      flex: 1, 
      minWidth: 150,
    },
  ];

  useEffect(() => {
    const getTotalRepairCost = async () => {
      try {
        const options = {
          'method': 'POST',
          'url' : '/ServiceRequests/getTotalRepairCost.json',
        }
        const response = await axios(options);
        const { totalRepairCost } = response.data;

        setTotalRepairCost(totalRepairCost)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getTotalRepairCost();
  }, [onEdit]);

  useEffect(() => {
    const getAssetTypes = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/ServiceRequests/getAllServiceRequests.json',
        }
        const response = await axios(options);
        const data = response.data;

        setAssetDisposalData(data)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEdit]);

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/ServiceRequests/getAllServiceRequestsId.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);
        
        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AddServiceRequest setOnEdit={setOnEdit}/>
      </Box>
      
      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        keepNonExistentRowsSelected 
        rows={assetDisposalData}
        columns={filteredAndOrderedColumns}
        getRowHeight={() => 'auto'}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => <CustomToolbar 
            selectedRows={rowSelectionModel} 
            columns={columns}
            setOnSaveColumns={setOnSaveColumns}
            functionName={functionName}
            modelTable={modelTable}
            handleClickAllData={handleClickAllData}
            selectAll={selectAll}
          />,
          footer: () => <CustomFooter totalRepairCost={totalRepairCost} selectedRows={rowSelectionModel.length}/>
        }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['asc', 'desc']}
        disableRowSelectionOnClick
        autoHeight
        loading={loading}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default ServiceRequest