import { Autocomplete, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, StyledEngineProvider, TextField, Tooltip, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import useViewAssetContext from '../../hooks/UseViewAssetContext';
import useCommentsContext from '../../hooks/UseCommentsContext';
import { AddLink, LinkOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function AsssetServiceCallUnlink({assetCode}) {
  const {setDoneUpdating} = useViewAssetContext();
  const {setDoneUpdate} = useCommentsContext();
  const axios = AxiosCustomConfig();
  const [open,setOpen] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [dataRequests, setdataRequests] = useState([]);
  const [dataRequestsLoading, setdataRequestsLoading] = useState(false);
  const [opendataRequests, setOpendataRequests] = useState(false);
  const [dataRequestsData, setdataRequestsData] = useState({
    asset_code: '',
    request_codes: [],
    distributor_company_id: '', 
  });
  const [selectedRequests, setSelectedRequests] = useState([]);
  const allSelected = selectedRequests.length === dataRequests.length;
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    setdataRequestsData(
      (prevData) => ({ ...prevData, asset_code: assetCode})
    )

  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
    setSelectedRequests([])
    setdataRequests([])
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedRequests.length === 0) {
        setOpenMessageDialog(true);
        setMessage('Please Select request to unlink.')
        return
    }
    
    setShowProgress(true);
    try {
        const options = {
          method: 'POST',
          url: '/ServiceRequests/removeLinkForServiceRequests.json',
          data: objectToFormData(dataRequestsData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdating((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setdataRequestsData({
      request_codes: [],
      distributor_company_id: '', 
    });
  };

  const getdataRequests = async (ignore) => {
    try {
      setdataRequestsLoading(true)
        const options = {
            method: 'POST',
            url: '/ServiceRequests/getRequestsPerLinkAsset.json',
            data: {asset_code: assetCode},
        }
        const response = await axios(options);
        if(!ignore) {
          setdataRequests(response.data);
        }
        
        setdataRequestsLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false
    getdataRequests(ignore);
    return () => {
      ignore = true
    };
  }, [open]);

  const handleSelectAll = () => {
    setSelectedRequests(allSelected ? [] : dataRequests.map((request) => request.request_code));
  };

  const handleToggle = (request_code) => {
    setSelectedRequests((prev) =>
      prev.includes(request_code) ? prev.filter((code) => code !== request_code) : [...prev, request_code]
    );
  };

  useEffect(() => {
    setdataRequestsData((prevData) => ({
      ...prevData,
      request_codes: selectedRequests, // Sync dataRequests with selectedRequests
    }));
  }, [selectedRequests]);

  return (
    <div>
    <Tooltip title="Unlink" arrow>
      <IconButton
        onClick={(e) => handleOpen(e)}
        size="small"
        sx={{
          width: 35,
          height: 35,
          borderRadius: 1,
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <LinkOff />
      </IconButton>
    </Tooltip>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Unlink Record</DialogTitle>
      <DialogContent>
      <Grid container spacing={3} mt={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAll}
                  color="primary"
                />
              }
              label="Select All"
            />
            {dataRequests.map((request) => (
              <div key={request.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <Checkbox
                  checked={selectedRequests.includes(request.request_code)}
                  onChange={() => handleToggle(request.request_code)}
                  color="primary"
                />
                <Link to={`/serviceCallRequests/${request.request_code}`} style={{ textDecoration: 'underline', color: 'blue', marginLeft: '8px' }}>
                  {request.request_code}
                </Link>
              </div>
            ))}

          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AsssetServiceCallUnlink