import {Box, Chip, StyledEngineProvider, Tooltip} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddAuditAsset from '../AuditAssetAgent/AddAuditAsset';
// import EditAuditAsset from './EditAuditAsset';
// import DeleteAuditAsset from './DeleteAuditAsset';
import CloseAuditAsset from '../AuditAssetAgent/CloseAuditAsset';
import AssetAuditLink from './AssetAuditLink';
import AssetAuditUnlink from './AssetAuditUnlink';
import { Link } from 'react-router-dom';

const AssetAudit = ({assetCode}) => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [auditAssets, setAuditAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);

  const columns = [
    { field: 'asset_code', headerName: 'Equipment no.', flex: 1, minWidth: 150, wrap: true },
    { field: 'asset_name', headerName: 'Asset Name',flex: 1, minWidth: 150, wrap: true  },
    { field: 'store_name', headerName: 'Store name name',flex: 1, minWidth: 150,
      renderCell: (params) => {
        const store_name = params.value;
        const unique_code = params.row.account_unique_code;
        const fetchCustomerDetails = async () => {
          try {
            // Replace with your API call to fetch customer details by ID
            const options = {
              'method': 'POST',
              'url' : '/ApiAccounts/getStoreDetails.json',
              'data': {unique_code:unique_code}
            }
            const response = await axios(options);
            const {data} = response.data;
            setCustomerDetails(data); // Save customer details in state
          } catch (error) {
            console.error("Error fetching customer details:", error);
          }
        };
        return (
          <Tooltip
            title={customerDetails ? (
              <>
                <div>Id: {customerDetails.id}</div>
                <div>Ship to / Business Address: {customerDetails.business_address}</div>
                <div>Customer No: {customerDetails.customer_no}</div>
                <div>Distributor/Chain Account: {customerDetails.distributor_account || customerDetails.customer_chain_static}</div>
              </>
            ) : 'Loading...'}
            arrow
            placement="right"
            onOpen={fetchCustomerDetails}
          >
            <Link to={`/customers/${unique_code}`}>
              {store_name}
            </Link>
          </Tooltip>
        );
      }
    },
    { field: 'branch_name', headerName: 'Branch name',flex: 1, minWidth: 150,},
    { field: 'remarks', headerName: 'Remarks',flex: 1, minWidth: 150,},
    { 
      field: 'status', 
      headerName: 'Status',
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      renderCell: (params) => {
          const statusName = params.value;
          if (statusName === 0) {
            return <Chip label='Processing' color='primary' />;
          } else if (statusName === 1) {
            return <Chip label='Closed' color='success' />;
          } else {
            return '';
          }
          
      }
  },
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 200,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 250,
      wrap: true,
      renderCell: (params) => (
        <>
          <CloseAuditAsset auditAssets={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          {/* <EditAuditAsset auditAssets={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteAuditAsset auditAssets={params.row} setOnEditOrDelete={setOnEditOrDelete}/> */}
        </>
      ),
    },
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getAuditPerAsset.json',
          'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        const data = response.data;

        setAuditAssets(data)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      } finally {
        setLoading(false);
      }
    }

    getAssetTypes();
  }, [onEditOrDelete]);
  return (
    <>
      <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* Container for left-aligned buttons */}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <AddAuditAsset assetCode={assetCode} setOnEditOrDelete={setOnEditOrDelete}/>
        </Box>

        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Link unlink buttons */}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <AssetAuditLink assetCode={assetCode}/>
          <AssetAuditUnlink assetCode={assetCode}/>
        </Box>
      </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        autoHeight
        rows={auditAssets}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        loading={loading}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default AssetAudit