import { Box, Breadcrumbs, Button, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddAccessories from './AddAccessories';
import EditAccessories from './EditAccessories';
import DeleteAccessories from './DeleteAccessories';
import { useTheme } from '@emotion/react';
import { Link, useParams } from 'react-router-dom';


const DeploymentAccessoriesList = () => {
  const theme = useTheme();
  const { requestCode } = useParams();
  const axios = AxiosCustomConfig();
  const [users, setUsers] = useState([]);
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [doneUpdateRequest, setDoneUpdateRequest] = useState(false);
  const [assetBrands, setAssetBrands] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 250,
      wrap: true,
      renderCell: (params) => (
        <Box sx={{ display: 'flex' , gap: 1}}>
          <EditAccessories accessoryData={params.row} setDoneUpdateRequest={setDoneUpdateRequest} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteAccessories accessoryData={params.row} setDoneUpdateRequest={setDoneUpdateRequest} setOnEditOrDelete={setOnEditOrDelete}/>
          <Button 
            variant="outlined" 
            size="small" 
            sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
            onClick={() => { window.location.href = `/request/${requestCode}/accessories/${params.row.id}`; }}
          >
          View
          </Button>
        </Box>
      ),
    },
    { field: 'accessories', headerName: 'Accessories', flex: 1, minWidth: 200, wrap: true, },
    { field: 'quantity', headerName: 'Quantity',flex: 1, minWidth: 150},
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150, wrap: true  },
    { field: 'created', headerName: 'Date added',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        if (params === '0000-00-00 00:00:00' || !params) {
          return '';
        }
        const date = new Date(params);
        
        if (isNaN(date.getTime())) {
          return ''; 
        }
        
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Date updated',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        if (params === '0000-00-00 00:00:00' || !params) {
          return '';
        }
        const date = new Date(params);
        
        if (isNaN(date.getTime())) {
          return ''; 
        }
        
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
  ];

  useEffect(() => {
    const getAllAccessories = async () => {
      try {
        setLoading(true)
        const options = {
          'method': 'POST',
          'url' : '/DeploymentRequestAccessories/getAllAccessoriesPerRequest.json',
          'data' : {request_code: requestCode}
        }
        const response = await axios(options);
        const data = response.data;

        setAssetBrands(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAllAccessories();
  }, [onEditOrDelete]);
  return (
    <>
    <Box mb={3}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          to="/deploymentRequest"
        >
          Request
        </Link>
        <Link
          color="inherit"
          to={`/request/${requestCode}`}
        >
          {requestCode}
        </Link>
        <Typography color="text.primary">Select Accessories</Typography>
      </Breadcrumbs>
    </Box>
    <Box sx={{ marginBottom: '20px' }}>
      <AddAccessories requestCode={requestCode} setDoneUpdateRequest={setDoneUpdateRequest} setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        autoHeight
        rows={assetBrands}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        loading={loading}
        pageSizeOptions={[5, 10]}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default DeploymentAccessoriesList