import { Box, Button, Chip, Grid, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import MessageDialog from '../Utils/MessageDialog'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import useJobOrdersContext from '../../hooks/UseJobOrdersContext';
import { useTheme } from '@emotion/react';
import AddAccessories from './AddAccessories';
import EditAccessories from './EditAccessories';
import DeleteAccessories from './DeleteAccessories';

function AccessoriesList({setDoneUpdateRequest}) {
  const theme = useTheme();
  const { requestCode } = useParams();
  const axios = AxiosCustomConfig();
  const {doneUpdate} = useJobOrdersContext();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 250,
      wrap: true,
      renderCell: (params) => (
        <Box sx={{ display: 'flex' }}>
          <EditAccessories accessoryData={params.row} setDoneUpdateRequest={setDoneUpdateRequest} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteAccessories accessoryData={params.row} setDoneUpdateRequest={setDoneUpdateRequest} setOnEditOrDelete={setOnEditOrDelete}/>
          <Button 
            variant="outlined" 
            size="small" 
            sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
            onClick={() => { window.location.href = `/request/${requestCode}/accessories/${params.row.id}`; }}
          >
          View
          </Button>
        </Box>
      ),
    },
    { field: 'accessories', headerName: 'Accessories', flex: 1, minWidth: 200, wrap: true, },
    { field: 'quantity', headerName: 'Quantity',flex: 1, minWidth: 150},
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150, wrap: true  },
    { field: 'created', headerName: 'Date added',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        if (params === '0000-00-00 00:00:00' || !params) {
          return '';
        }
        const date = new Date(params);
        
        if (isNaN(date.getTime())) {
          return ''; 
        }
        
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Date updated',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        if (params === '0000-00-00 00:00:00' || !params) {
          return '';
        }
        const date = new Date(params);
        
        if (isNaN(date.getTime())) {
          return ''; 
        }
        
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
  ];

  useEffect(() => {
    let ignore = false;
    const getAssetTypes = async (ignore) => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/DeploymentRequestAccessories/getAllAccessoriesPerRequest.json',
          'data' : {request_code : requestCode}
        }
        const response = await axios(options);
        const data = response.data;

        if (!ignore) {
            setServiceRequests(data)
        }
        
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      } finally {
        setLoading(false);
      }

      return () => {
        ignore = true;
        setLoading(false);
      }
    }

    getAssetTypes(ignore);
  }, [onEditOrDelete, doneUpdate]);
  return (
    <>
    <Box p={2}>
      <Link to={`/request/${requestCode}/accessories`}>Select Accessories</Link>
    </Box>
    <Box p={2} >
      <Box>
        <AddAccessories requestCode={requestCode} setDoneUpdateRequest={setDoneUpdateRequest} setOnEditOrDelete={setOnEditOrDelete}/>
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        autoHeight
        keepNonExistentRowsSelected
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={serviceRequests}
        columns={columns}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default AccessoriesList