import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';


function EditAccessories({accessoryData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    accessory: '',
    asset_types: [],
    asset_type_name: '',
    accessory_types: [],
    accessory_type: '',
    quantity: '',
    id: ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [openAssetTypes, setOpenAssetTypes] = useState(false);
  const [loadingAssetTypes, setLoadingAssetTypes] = useState(false);
  const [accessoryType, setAccessoryType] = useState([]);
  const [openAccessoryType, setOpenAccessoryType] = useState(false);
  const [loadingAccessoryType, setLoadingAccessoryType] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { accessory_type, id, asset_type, accessory, quantity} = accessoryData;
    setEditData((prevData) => ({ 
      ...prevData, 
      accessory_type: accessory_type || prevData.accessory_type, 
      accessory_types: {'label': accessory_type, value: accessory_type}, 
      asset_types: {'label': asset_type, value: asset_type}, 
      asset_type_name: asset_type || prevData.asset_type, 
      accessory: accessory || prevData.accessory, 
      quantity: quantity || prevData.quantity, 
      id: id || prevData.id,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      accessory: '',
      asset_types: [],
      asset_type_name: '',
      accessory_types: [],
      accessory_type: '',
      quantity: '',
      id: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!editData.accessory_type) {
        setOpenMessageDialog(true);
        setMessage('Accessory type is required')
        return
    }

    if (!isValidSpecialCharacter(editData.accessory_type)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Accessory type.')
        return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
            url: '/Accessories/updateAccessory.json',
            data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }


  const handleAssetTpyesChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, asset_types: selectValue, asset_type_name: selectValue.asset_type_name}))
    }
  }
  const handleAccessoryTypeChange = (e, selectValue) => {
    if (selectValue) {
      console.log(selectValue.accessory_type);
      
      setEditData((prevData) => ({ ...prevData, accessory_types: selectValue, accessory_type: selectValue.accessory_type}))
    }
  }


  const getAssetTypes = async (ignore) => {
    try {
      setLoadingAssetTypes(true)
        const options = {
          method: 'POST',
          url: '/AssetTypes/getAssetTypeNameAndCode.json',
      }

      const response = await axios(options);
      if (!ignore) {
        setAssetTypes(response.data);
      }
      
      setLoadingAssetTypes(false)
    } catch (error) {
      console.log('Error info: ', error);
    } finally {
      setLoadingAssetTypes(false)
    }
  }

  const getAccessoryTypes = async (ignore) => {
    try {
      setLoadingAccessoryType(true)
        const options = {
          method: 'POST',
          url: '/AccessoryTypes/getAccessoryTypesForAutoComplete.json',
      }

      const response = await axios(options);
      if (!ignore) {
        setAccessoryType(response.data);
      }
      
      setLoadingAccessoryType(false)
    } catch (error) {
      console.log('Error info: ', error);
    } finally {
      setLoadingAccessoryType(false)
    }
  }


  useEffect(()=>{
    let ignore = false;

    if (!openAssetTypes) {
      setAssetTypes([])
    }

    if (!openAssetTypes) return undefined
    getAssetTypes(ignore)

    return () => {
      ignore = true;
    }
  }, [openAssetTypes]);

  useEffect(()=>{
    let ignore = false;

    if (!openAccessoryType) {
      setAccessoryType([])
    }

    if (!openAccessoryType) return undefined
    getAccessoryTypes(ignore)

    return () => {
      ignore = true;
    }
  }, [openAccessoryType]);

  return (
    <>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Accessory type</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
          <Grid item xs={12}>
              <TextField
                  name="accessory"
                  label="Accessory*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, accessory: e.target.value }))}
                  value={editData.accessory}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                open={openAccessoryType}
                onOpen={() => {setOpenAccessoryType(true);}}
                onClose={() => {setOpenAccessoryType(false);}}
                onChange={handleAccessoryTypeChange}
                loading={loadingAccessoryType}
                options={accessoryType}
                value={editData.accessory_types}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Accessory type*"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAccessoryType ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                open={openAssetTypes}
                onOpen={() => {setOpenAssetTypes(true);}}
                onClose={() => {setOpenAssetTypes(false);}}
                onChange={handleAssetTpyesChange}
                loading={loadingAssetTypes}
                options={assetTypes}
                value={editData.asset_types}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Asset type*"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAssetTypes ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  name="quantity"
                  label="Quantity"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    const input = e.target.value;
                    // Regular expression to allow up to 11 digits
                    if (/^\d{0,64}$/.test(input)) {
                      setEditData((prevData) => ({ ...prevData, quantity: input }));
                    }
                  }}
                  value={editData.quantity}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </>
  )
}

export default EditAccessories