import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { HighlightOff } from '@mui/icons-material';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useShopRepairContext from '../../hooks/useShopRepairContext';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
function RejectRepair({repairDetails, setonRejectOrApprove}) {
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useShopRepairContext();
  const [open, setOpen] = useState(false);
  const [rejectData, setRejectData] = useState({
    id: '', 
    code: '', 
    remarks: '',
    est_repair_cost: '',
    asset_code: '',
    scrap_date: dayjs(),
  });
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true)
    const {id, request_code, repair_cost, asset_code} = repairDetails;
    setRejectData((prevData) => ({...prevData, id, request_code, est_repair_cost:repair_cost, asset_code}));
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleReject = async (e) => {
    e.stopPropagation();
    if(!rejectData.remarks) {
      setShowProgress(false)
      setOpenMessageDialog(true)
      setMessage('PLease input remarks for rejecting the request.')
      return
    }

    setShowProgress(true)
    try {
      const options = {
        method: 'POST',
        url: '/ShopRepairs/rejectRequest.json',
        data: objectToFormData(rejectData),
      }

      const response = await axios(options);

      setShowProgress(false)

      if (response.data.is_success) {
        setDoneUpdate((prev) => !prev)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
        setOpen(false)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true)
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  return (
    <>
      <Button 
        disabled={repairDetails.status === 0 ? false : true} 
        variant='contained' 
        size="small" 
        color='error' 
        startIcon={<HighlightOff />} 
        onClick={handleOpen}
        >
        Reject repair
      </Button> 
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
      <DialogTitle>Reject Request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align='center'>
            Are you sure you want to reject this Request? <p> <strong> {repairDetails.request_code}</strong>  </p>
          </DialogContentText>

          <Box sx={{marginBottom: 2 }}>
            <Chip label="Assets" size="small" color="info" />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        views={['year', 'month']} // Restrict to month and year selection
                        label="Scrap Date*"
                        value={dayjs(rejectData.scrap_date, 'MMM YYYY')} // Convert to dayjs object with the given format
                        onChange={(newValue) =>
                            setRejectData((prevData) => ({
                                ...prevData,
                                scrap_date: newValue, // Format as MMM/YYYY
                            }))
                        }
                        maxDate={dayjs()} // Restrict future months
                        format="MMM YYYY"
                    />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                  <TextField
                      name="name"
                      label="Estimated Repair Cost"
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        const input = e.target.value;
                        // Regular expression to allow numbers with optional decimal point
                        if (/^\d*\.?\d*$/.test(input)) {
                            const numericValue = parseFloat(input);
                            // Check if the numeric value is within the range [0, 1000000]
                            if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 10000000) {
                              setRejectData((prevData) => ({ ...prevData, est_repair_cost: input }));
                            }
                        }
                    }}
                      value={rejectData.est_repair_cost}
                  />
              </FormControl>
            </Grid>
            
          </Grid>

          <Box sx={{marginBottom: 2, marginTop: 2 }}>
            <Chip label="Shop repair" size="small" color="info" />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>Remarks:</Typography>
              <Textarea 
                aria-label="minimum height" 
                minRows={3} 
                placeholder="Input Remarks" 
                onChange={(e) => setRejectData((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
              />
            </Grid>
          </Grid>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, paddingTop: 3}}>
            <FormControl fullWidth>
              <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
            </FormControl>
            <FormControl fullWidth>
              <Button variant="contained" size="small" color='error' autoFocus onClick={handleReject}>Reject</Button>
            </FormControl>
        </DialogActions>
        </DialogContent>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            setonRejectOrApprove((prev) => !prev)
            } }
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default RejectRepair